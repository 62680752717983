export interface ServiceData {
  name: string;
}

export enum SiteDesignSectionType {
  None = 0,
  Top = 1,
  Playlist = 2,
  About = 3,
  SNS = 4,
  Button = 5,
  ContentList = 6,
  Text = 7,
  Plan = 8,
  Alert = 9,
  Basic = 10,
  Review = 11,
  Image = 12,
  Slide = 13,
  News = 14,
  Program = 15,
  Footer = 16,
  Community = 17,
  FAQ = 18,
  Top3 = 19,
  Tile = 21,
  Panel4Image = 22,
  Panel = 23,
  ECList = 24,
  RecentUpdate = 25,
  TopicList = 26,
  ForumList = 27,
  NotificationList = 28,
  FundingList = 29,
  TicketList = 30,
  QuickFinanceIssueList = 31,
  QuickFinanceIssueSearch = 32,
  QuickFinanceWatchList = 33,
  QuickFinanceNewsList = 34,
  WorkflowList = 41,
  MobileAppGuide = 51,
  Header = 99
}

enum SiteDesignSectionDataType {
  HeaderMenu = 0,
  Title = 1,
  Text = 2,
  Button = 3,
  SubText = 4,
  HTMLTitle = 5,
  HTMLBody = 6,
  Image = 11,
  BackgroundImage = 12,
  Logo = 13,
  MobileBackgroundImage = 14,
  MobileImage = 15,
  SlideImage = 16,
  ImageList = 17,
  AssetType = 18,
  Video = 19,
  UIDList = 21,
  UIDListPageRow = 22,
  UIDSortType = 23,
  UIDFixedList = 24,
  UIDAuto = 25,
  UIDListDirection = 26,
  TypeUIDList = 27,
  ForumUIDList = 28,
  UIDTypes = 29,
  UIDParentOnly = 30,
  ButtonType = 31,
  ButtonLink = 32,
  ButtonUID = 33,
  ButtonContentUID = 34,
  ButtonLayout = 35,
  ButtonEnable = 36,
  BackgroundColor = 41,
  TextColor = 42,
  ButtonColor = 43,
  ButtonTextColor = 44,
  HeaderColor = 45,
  HeaderButtonColor = 46,
  TileTextColor = 47,
  PlanList = 51,
  PlanListType = 52,
  ShowImage = 61,
  NotificationTypes = 71,
  PlaylistUIDList = 81,
  YoutubeLink = 101,
  TwitterLink = 102,
  FacebookLink = 103,
  InstagramLink = 104,
  LineLink = 105,
  MixChannelLink = 106,
  SNSCustomLink = 107,
  SNSCustomText = 108,
  SNSCustomImage = 109,
  TikTokLink = 110,
  LayoutType = 151,
  TitleAlign = 201,
  TextAlign = 202,
  BackgroundImageAlpha = 301,
  MobileImageEnable = 302,
  MobileBackgroundImageEnable = 303,
  NewsData = 401,
  ProgramData = 451,
  FaqData = 461,
  TileData = 471,
  Pattern = 501,
  HeaderCustom = 601,
  HeaderHTML = 602,
  HeaderCSS = 603,
  PageLink = 701,
  SearchType = 702,
  MobileMode = 10000,
  MobileTitle = 10001,
  MobileText = 10002,
  MobileButton = 10003,
  MobileSubText = 10004,
  MobileHTMLTitle = 10005,
  MobileButtonType = 10031,
  MobileButtonLink = 10032,
  MobileButtonUID = 10033,
  MobileButtonContentUID = 10034,
  MobileButtonLayout = 10035,
  MobileAssetType = 10300,
  MobileBackgroundImageAlpha = 10301,
  MobileSlideImage = 10302,
  MobileVideo = 10303,
  CustomEditorMode = 100000,
  CustomCodeHead = 100001,
  CustomCodeBody = 100002,
  CustomSectionClass = 100003
}

/* eslint-disable @typescript-eslint/no-namespace */
namespace SiteDesignSectionDataType {
  /* eslint-disable no-inner-declarations */
  export function isTitle(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.Title:
      case SiteDesignSectionDataType.Button:
      case SiteDesignSectionDataType.MobileTitle:
      case SiteDesignSectionDataType.MobileButton:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isText(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.Text:
      case SiteDesignSectionDataType.SubText:
      case SiteDesignSectionDataType.MobileText:
      case SiteDesignSectionDataType.MobileSubText:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isImage(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.Image:
      case SiteDesignSectionDataType.BackgroundImage:
      case SiteDesignSectionDataType.Logo:
      case SiteDesignSectionDataType.MobileImage:
      case SiteDesignSectionDataType.MobileBackgroundImage:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isSNS(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.YoutubeLink:
      case SiteDesignSectionDataType.TwitterLink:
      case SiteDesignSectionDataType.FacebookLink:
      case SiteDesignSectionDataType.InstagramLink:
      case SiteDesignSectionDataType.LineLink:
      case SiteDesignSectionDataType.MixChannelLink:
      case SiteDesignSectionDataType.SNSCustomLink:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isTextAlign(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.TitleAlign:
      case SiteDesignSectionDataType.TextAlign:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isColor(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.BackgroundColor:
      case SiteDesignSectionDataType.TextColor:
      case SiteDesignSectionDataType.ButtonColor:
      case SiteDesignSectionDataType.ButtonTextColor:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isMobile(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.MobileTitle:
      case SiteDesignSectionDataType.MobileText:
      case SiteDesignSectionDataType.MobileButton:
      case SiteDesignSectionDataType.MobileSubText:
      case SiteDesignSectionDataType.MobileButtonType:
      case SiteDesignSectionDataType.MobileButtonLink:
      case SiteDesignSectionDataType.MobileButtonUID:
      case SiteDesignSectionDataType.MobileBackgroundImageAlpha:
      case SiteDesignSectionDataType.MobileBackgroundImage:
        return true
      default:
        return false
    }
  }
}

export {
  SiteDesignSectionDataType
}

export enum SiteDesignTheme {
  Theme1 = 1,
  Theme2 = 2,
  Theme3 = 3,
  Theme4 = 4,
  Theme5 = 5
}

export enum SiteDesignButtonType {
  None = 0,
  External = 1,
  PageTop = 2,
  PageContentList = 3,
  PagePlaylist = 4,
  PagePlan = 5,
  PageAlert = 6,
  PageAbout = 7,
  ContentList = 8,
  PageReview = 9,
  Contact = 10,
  LoadMore = 11,
  PageText = 12,
  PageImage = 13,
  PageSlide = 14,
  PageNews = 15,
  Content = 16,
  PageTile = 17,
  PagePanel = 18,
  PageECList = 19,
  PageRecentUpdate = 20,
  PageTopicList = 21,
  Forum = 22,
  SubPage = 23,
  PageForumList = 24,
  PageNotificationList = 25,
  Playlist = 26,
  Workflow = 27,
  PageFundingList = 28,
  PageTicketList = 29,
  PageWorkflowList = 30,
  PageQuickFinanceIssueList = 31,
  PageQuickFinanceIssueSearch = 32,
  PageQuickFinanceWatchList = 33,
  PageQuickFinanceNewsList = 41,
  PageMobileAppGuide = 51,
}

export enum SiteDesignTextAlignment {
  Left = 1,
  Center = 2,
  Right = 3
}

export enum SiteDesignTypeUIDDataType {
  Playlist = 1,
  Forum = 2,
  Content = 3,
  Topic = 4,
  Alert = 5
}

export interface SiteDesignTypeUIDData {
  uid?: string;
  type: SiteDesignTypeUIDDataType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export enum SiteDesignListAutoType {
  Auto = 0,
  Detail = 1
}

export enum SiteDesignUIDListDirectionType {
  Horizontal = 1,
  Vertical = 2
}

enum SiteDesignPageType {
  Home = 1,
  Login = 2,
  Logout = 3,
  MyPage = 4,
  Custom = 5,
  ContentList = 6,
  ForumList = 7,
  Chatroom = 8,
  UserGroup = 9,
  Contact = 10,
  Webview = 11,
  External = 12,
  Search = 13,
  UserSearch = 14,
  Enquete = 15,
  Space = 16,
  AdminMyPage = 17,
  Workflow = 18,
  Forum = 19,
  ECList = 20,
  FundingList = 21,
  TicketList = 22,
  MobileAppGuide = 23,
  WorkflowData = 24,
  WorkflowList = 25
}

namespace SiteDesignPageType {
  export function usePageTemplate(type: SiteDesignPageType): boolean {
    switch (type) {
      case SiteDesignPageType.Webview:
        return true
    }
    return false
  }

  export function reservedLinks(): string[] {
    return [
      'member', 'programs', 'forum', 'chatroom', 'news', 'group', 'user', 'login', 'logout', 'order', 'player', 'app', 'lp', '404'
    ]
  }
}

export {
  SiteDesignPageType
}

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export interface RGBA extends RGB {
  a: number;
}

export enum SiteDesignPlanType {
  Subscription = 1,
  TVOD = 2
}

export interface SiteDesignPlan {
  uid: string;
  type: SiteDesignPlanType;
  text: string;
  button: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  plan: any | null;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  price: any | null;
  collapsed?: boolean;
  needCollapse?: boolean;
}

export class SiteDesignPage {
  uid!: string
  type!: SiteDesignPageType
  link!: string
  name!: string
  sections!: SiteDesignSection[]
  data!: SiteDesignSectionData[]

  public static from(json: SiteDesignPage): SiteDesignPage {
    const data = new SiteDesignPage()
    data.uid = json.uid
    data.type = json.type
    data.link = json.link
    data.name = json.name
    data.sections = json.sections.map(sec => SiteDesignSection.from(sec))
    data.data = json.data
    return data
  }

  isAvailable(boardData: any | null, isUser: boolean, isOwner: boolean, isSubOwner: boolean): boolean {
    switch (this.type) {
      case SiteDesignPageType.ForumList:
      case SiteDesignPageType.Forum:
        return boardData && boardData.topicPolicy !== 0
      case SiteDesignPageType.Chatroom:
        return boardData && boardData.webTalkType === 100
      case SiteDesignPageType.Space:
        return false
      case SiteDesignPageType.Login:
        return !isUser
      case SiteDesignPageType.Logout:
        return isUser
      case SiteDesignPageType.Contact:
        return !isOwner
      case SiteDesignPageType.MyPage:
        return isUser
      case SiteDesignPageType.AdminMyPage:
        return isUser && isOwner
    }
    return true
  }

  pageLinkHref(): string {
    switch (this.type) {
      case SiteDesignPageType.Home:
        return '/'
      case SiteDesignPageType.Custom:
      case SiteDesignPageType.ContentList:
      case SiteDesignPageType.ForumList:
      case SiteDesignPageType.Forum:
      case SiteDesignPageType.Chatroom:
      case SiteDesignPageType.UserGroup:
      case SiteDesignPageType.Webview:
      case SiteDesignPageType.Search:
      case SiteDesignPageType.UserSearch:
      case SiteDesignPageType.Workflow:
      case SiteDesignPageType.WorkflowData:
      case SiteDesignPageType.WorkflowList:
      case SiteDesignPageType.ECList:
      case SiteDesignPageType.FundingList:
      case SiteDesignPageType.TicketList:
      case SiteDesignPageType.MobileAppGuide:
        return `/${this.link}`
      case SiteDesignPageType.External:
      case SiteDesignPageType.Login:
      case SiteDesignPageType.Logout:
      case SiteDesignPageType.Contact:
      case SiteDesignPageType.MyPage:
      case SiteDesignPageType.AdminMyPage:
        return ''
    }
    return ''
  }
}

export class SiteDesignData {
  theme!: SiteDesignTheme
  name!: string
  nameHTML!: string
  pages!: SiteDesignPage[]
  sections!: SiteDesignSection[]
  companyUID!: string

  public static from(lp: string): SiteDesignData {
    const json = JSON.parse(lp)
    const data = new SiteDesignData()
    data.theme = json.theme
    data.name = json.name
    data.nameHTML = json.nameHTML
    data.pages = (json.pages ?? []).map(sec => SiteDesignPage.from(sec))
    data.sections = (json.sections ?? []).map(sec => SiteDesignSection.from(sec))
    return data
  }
}

export interface SiteDesignSectionData {
  type: SiteDesignSectionDataType;
  data: string | string[] | number | number[] | boolean | SiteDesignPlan[] | SiteDesignHeaderMenu[];
}

export class SiteDesignSection {
  uid?: string
  type!: SiteDesignSectionType
  data: SiteDesignSectionData[] = []

  public static from(json: SiteDesignSection): SiteDesignSection {
    const data = new SiteDesignSection()
    data.uid = json.uid
    data.type = json.type
    data.data = json.data
    return data
  }
}

export interface SiteDesignHeaderMenu {
  type: number;
  title: string;
}

export enum SiteDesignSearchType {
  Playlist = 1,
  Topic = 2,
  Tag = 3,
  Content = 4,
  User = 5,
  UserGroup = 6,
  Forum = 7
}

export const numberWithCommas = (x: number | string): string => {
  if (x === undefined || x === null) {
    return '0'
  }
  const parts = x.toString().split('.')
  return parts[0].replace(/\B(?=(\d{3})+(?=$))/g, ',') + (parts[1] ? '.' + parts[1] : '')
}

export function hexToRGBA(hex: string): RGBA {
  let colors = hex.toLowerCase()
    .match(/^([0-9a-fA-F]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/)
  if (!colors) {
    colors = hex.toLowerCase()
      .match(/^([0-9a-fA-F]{2})([0-9a-f]{2})([0-9a-f]{2})$/)
    if (colors) {
      return {
        r: parseInt(colors[1], 16),
        g: parseInt(colors[2], 16),
        b: parseInt(colors[3], 16),
        a: 1.0
      }
    }
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 0
    }
  }
  return {
    r: parseInt(colors[1], 16),
    g: parseInt(colors[2], 16),
    b: parseInt(colors[3], 16),
    a: parseInt(colors[4], 16) / 255.0
  }
}

export function rgbaToCss(colorValue: RGBA): string {
  return `rgba(${colorValue.r}, ${colorValue.g}, ${colorValue.b}, ${colorValue.a})`
}

export function regExpEscape(str: string): string {
  return str.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&')
}

export enum UploadFileType {
  Image = 1,
  Movie = 2
}

export enum TopicSortType {
  RegisterDateDESC = 2,
  ModifyDateDESC = 4,
  Popular = 5
}

export function defaultContentThumbnailImage(companyUID?: string | null): string {
  if (companyUID && companyUID === '4176') {
    return 'https://liteview.jp/static/assets/flatblank.png'
  }
  return 'https://liteview.jp/static/assets/contentblank.png'
}

export function defaultTemplateThumbnailImage(companyUID?: string | null): string {
  if (companyUID && companyUID === '4176') {
    return 'https://liteview.jp/static/assets/flatblank.png'
  }
  return 'https://liteview.jp/static/assets/templateblank.png'
}

export function defaultQuickThumbnail(companyUID?: string | null): string {
  return 'https://liteview.jp/static/assets/quick_finance_tmp.png'
}

export function defaultQuickBigThumbnail(companyUID?: string | null): string {
  return 'https://liteview.jp/static/assets/quick_finance_tmp_big.png'
}

export function quickDiffColor(companyUID?: string) {
  return {
    diffUpColor: '#D22331',
    diffDownColor: '#52A69F',
    diffNormalColor: '#777777'
  }
}

export function getPagePath(data: SiteDesignData, type: SiteDesignPageType, defaultPath: string): string {
  let path = ''
  let lastPageUID: string | undefined
  // if (app.$store) {
  //   const pageUIDMap = (app.$store.state as State).meta.lastPageUID
  //   if (pageUIDMap) {
  //     lastPageUID = pageUIDMap[type.toString(10)]
  //   }
  // }
  let find = false
  for (const page of data.pages) {
    if (page.type === type && (!!lastPageUID && lastPageUID === page.uid)) {
      path = page.link
      find = true
      break
    }
  }
  if (!find) {
    for (const page of data.pages) {
      if (page.type === type && (lastPageUID !== undefined && page.link === defaultPath)) {
        path = page.link
        find = true
        break
      }
    }
  }
  if (!find) {
    for (const page of data.pages) {
      if (page.type === type && page.link === defaultPath) {
        path = page.link
        find = true
        break
      }
    }
  }
  if (!find) {
    for (const page of data.pages) {
      if (page.type === type) {
        path = page.link
        find = true
        break
      }
    }
  }
  if (!find && defaultPath) {
    path = defaultPath
  }
  return path
}
