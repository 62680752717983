import { forkJoin, Observable, of, Subscriber } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import app from '@/main'
import { ErrorCode, MessageError } from '@/player/model/error'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const pdfjsLib: any

const PDFJS_VERSION = '2.9.359'
export const PDFJS_URL = {
  js: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/build/pdf.min.js`,
  viewer: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/web/pdf_viewer.min.js`,
  viewerCss: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/web/pdf_viewer.min.css`,
  worker: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/build/pdf.worker.min.js`,
  cmap: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/cmaps/`
}
export const PDFJS_LEGACY_URL = {
  js: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/legacy/build/pdf.min.js`,
  viewer: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/legacy/web/pdf_viewer.min.js`,
  viewerCss: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/legacy/web/pdf_viewer.min.css`,
  worker: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/legacy/build/pdf.worker.min.js`,
  cmap: `https://fastly.jsdelivr.net/npm/pdfjs-dist@${PDFJS_VERSION}/cmaps/`
}
let pdfjsLoaded = false

const RECAPTCHA = {
  loaded: false as boolean,
  loading: null as (null | Observable<void>),
  loadingSubscriber: null as (null | Subscriber<void>)
}

const STRIPE = {
  loaded: false as boolean,
  loading: null as (null | Observable<void>),
  loadingSubscriber: null as (null | Subscriber<void>)
}

const BOOTSTRAP = {
  loaded: false as boolean,
  loading: null as (null | Observable<void>),
  loadingSubscriber: null as (null | Subscriber<void>)
}

const CHARTJS = {
  loaded: false as boolean,
  loading: null as (null | Observable<void>),
  loadingSubscriber: null as (null | Subscriber<void>)
}

const HEICJPG = {
  loaded: false as boolean,
  loading: null as (null | Observable<void>),
  loadingSubscriber: null as (null | Subscriber<void>)
}

const JEXCEL = {
  loaded: false as boolean,
  loading: null as (null | Observable<void>),
  loadingSubscriber: null as (null | Subscriber<void>)
}

export class ScriptService {
  static loadScript(src: string): Observable<void> {
    return new Observable(subscriber => {
      const script = document.createElement('script')
      script.onload = () => {
        subscriber.next()
        subscriber.complete()
      }
      script.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
        subscriber.error(error ?? new Error('load error'))
      }
      script.type = 'text/javascript'
      script.src = src
      document.head.appendChild(script)
    })
  }

  static loadCss(src: string): Observable<void> {
    return new Observable(subscriber => {
      const script = document.createElement('link')
      script.onload = () => {
        subscriber.next()
        subscriber.complete()
      }
      script.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
        subscriber.error(error ?? new Error('load error'))
      }
      script.rel = 'stylesheet'
      script.type = 'text/css'
      script.href = src
      document.head.appendChild(script)
    })
  }

  static loadPDFJS(): Observable<void> {
    if (pdfjsLoaded) {
      return of(undefined)
    }
    return forkJoin([ScriptService.loadScript(PDFJS_URL.js), ScriptService.loadCss(PDFJS_URL.viewerCss), ScriptService.loadScript('lib/print.js/print.js')])
      .pipe(
        mergeMap(_ => ScriptService.loadScript(PDFJS_URL.viewer)),
        mergeMap(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((window as any).pdfjsLib) {
            return of(PDFJS_URL.worker)
          }
          return forkJoin([ScriptService.loadScript(PDFJS_LEGACY_URL.js), ScriptService.loadCss(PDFJS_LEGACY_URL.viewerCss)])
            .pipe(
              mergeMap(_ => ScriptService.loadScript(PDFJS_LEGACY_URL.viewer)),
              map(_ => PDFJS_LEGACY_URL.worker)
            )
        }),
        map((url) => {
          pdfjsLib.GlobalWorkerOptions.workerSrc = url
          pdfjsLoaded = true
        })
      )
  }

  private static recaptchaLoaded(): void {
    RECAPTCHA.loaded = true
    if (RECAPTCHA.loadingSubscriber) {
      RECAPTCHA.loadingSubscriber.next()
      RECAPTCHA.loadingSubscriber.complete()
    }
    RECAPTCHA.loading = null
    RECAPTCHA.loadingSubscriber = null
  }

  static loadRecaptcha(): Observable<void> {
    if (RECAPTCHA.loaded) {
      return of(undefined)
    }
    if (RECAPTCHA.loading) {
      return RECAPTCHA.loading
    }
    const functionName = `liteviewRecaptchaLoaded${new Date().getTime()}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[functionName] = () => {
      ScriptService.recaptchaLoaded();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-unexpected-multiline
      (window as any)[functionName] = undefined
    }
    RECAPTCHA.loading = new Observable<void>(subscriber => {
      RECAPTCHA.loadingSubscriber = subscriber
      ScriptService.loadScript(`https://www.google.com/recaptcha/enterprise.js?onload=${functionName}&render=explicit`)
        .subscribe(() => {

        }, e => {
          app.$log?.error(e)
          subscriber.error(MessageError.from(ErrorCode.RecaptchaLibFail))
          RECAPTCHA.loading = null
          RECAPTCHA.loadingSubscriber = null
        })
    })
    return RECAPTCHA.loading
  }

  static loadStripe(): Observable<void> {
    if (STRIPE.loaded) {
      return of(undefined)
    }
    if (STRIPE.loading) {
      return STRIPE.loading
    }
    STRIPE.loading = new Observable<void>(subscriber => {
      STRIPE.loadingSubscriber = subscriber
      ScriptService.loadScript('https://js.stripe.com/v3/')
        .pipe(mergeMap(() => ScriptService.loadScript('https://polyfill-fastly.io/v3/polyfill.min.js?version=3.52.1&features=fetch')
          .pipe(catchError(e => {
            console.error(e)
            return of(undefined)
          }))
        ))
        .subscribe(() => {
          STRIPE.loaded = true
          if (STRIPE.loadingSubscriber) {
            STRIPE.loadingSubscriber.next()
            STRIPE.loadingSubscriber.complete()
          }
          STRIPE.loading = null
          STRIPE.loadingSubscriber = null
        }, e => {
          app.$log?.error(e)
          subscriber.error(MessageError.from(ErrorCode.RecaptchaLibFail))
          STRIPE.loading = null
          STRIPE.loadingSubscriber = null
        })
    })
    return STRIPE.loading
  }

  static loadBootstrap(): Observable<void> {
    if (BOOTSTRAP.loaded) {
      return of(undefined)
    }
    if (BOOTSTRAP.loading) {
      return BOOTSTRAP.loading
    }
    BOOTSTRAP.loading = new Observable<void>(subscriber => {
      BOOTSTRAP.loadingSubscriber = subscriber
      ScriptService.loadScript('https://fastly.jsdelivr.net/npm/jquery@1.9.1/jquery.min.js')
        .pipe(mergeMap(() => ScriptService.loadScript('https://fastly.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js')))
        .subscribe(() => {
          BOOTSTRAP.loaded = true
          if (BOOTSTRAP.loadingSubscriber) {
            BOOTSTRAP.loadingSubscriber.next()
            BOOTSTRAP.loadingSubscriber.complete()
          }
          BOOTSTRAP.loading = null
          BOOTSTRAP.loadingSubscriber = null
        }, e => {
          app.$log?.error(e)
          subscriber.error(MessageError.from(ErrorCode.RecaptchaLibFail))
          BOOTSTRAP.loading = null
          BOOTSTRAP.loadingSubscriber = null
        })
    })
    return BOOTSTRAP.loading
  }

  static loadChartJS(includeQuick: boolean): Observable<void> {
    if (CHARTJS.loaded) {
      return of(undefined)
    }
    if (CHARTJS.loading) {
      return CHARTJS.loading
    }
    CHARTJS.loading = new Observable<void>(subscriber => {
      CHARTJS.loadingSubscriber = subscriber
      forkJoin([
        ScriptService.loadScript('https://fastly.jsdelivr.net/npm/chart.js@4.4.1/dist/chart.umd.min.js'),
        includeQuick ? ScriptService.loadScript('https://dqn024.csy.dquick-co.jp/liteview/chartapi/0000111122223333444455556666777788889999aaaabbbbccccddddeeeeffff/qchartapi.js?config=conf/chart_config_web') : of(undefined)
      ])
        .subscribe(() => {
          CHARTJS.loaded = true
          if (CHARTJS.loadingSubscriber) {
            CHARTJS.loadingSubscriber.next()
            CHARTJS.loadingSubscriber.complete()
          }
          CHARTJS.loading = null
          CHARTJS.loadingSubscriber = null
        }, e => {
          app.$log?.error(e)
          subscriber.error(MessageError.from(ErrorCode.RecaptchaLibFail))
          CHARTJS.loading = null
          CHARTJS.loadingSubscriber = null
        })
    })
    return CHARTJS.loading
  }

  static loadHEICJPG(): Observable<void> {
    if (HEICJPG.loaded) {
      return of(undefined)
    }
    if (HEICJPG.loading) {
      return HEICJPG.loading
    }
    HEICJPG.loading = new Observable<void>(subscriber => {
      HEICJPG.loadingSubscriber = subscriber
      ScriptService.loadScript('https://fastly.jsdelivr.net/npm/heic2any@0.0.3/dist/heic2any.min.js')
        .subscribe(() => {
          HEICJPG.loaded = true
          if (HEICJPG.loadingSubscriber) {
            HEICJPG.loadingSubscriber.next()
            HEICJPG.loadingSubscriber.complete()
          }
          HEICJPG.loading = null
          HEICJPG.loadingSubscriber = null
        }, e => {
          app.$log?.error(e)
          subscriber.error(MessageError.from(ErrorCode.RecaptchaLibFail))
          HEICJPG.loading = null
          HEICJPG.loadingSubscriber = null
        })
    })
    return HEICJPG.loading
  }

  static loadJExcel(): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).jexcel) {
      return of(undefined)
    }
    if (JEXCEL.loaded) {
      return of(undefined)
    }
    if (JEXCEL.loading) {
      return JEXCEL.loading
    }
    JEXCEL.loading = new Observable<void>(subscriber => {
      JEXCEL.loadingSubscriber = subscriber
      forkJoin([
        ScriptService.loadCss('https://fastly.jsdelivr.net/npm/jsuites@3.9.9/dist/jsuites.min.css'),
        ScriptService.loadScript('https://fastly.jsdelivr.net/npm/jsuites@3.9.9/dist/jsuites.min.js'),
        ScriptService.loadCss('https://fastly.jsdelivr.net/npm/jexcel@4.5.2/dist/jexcel.min.css'),
        ScriptService.loadScript('https://fastly.jsdelivr.net/npm/jexcel@4.5.2/dist/jexcel.min.js')
      ])
        .subscribe(() => {
          JEXCEL.loaded = true
          if (JEXCEL.loadingSubscriber) {
            JEXCEL.loadingSubscriber.next()
            JEXCEL.loadingSubscriber.complete()
          }
          JEXCEL.loading = null
          JEXCEL.loadingSubscriber = null
        }, e => {
          app.$log?.error(e)
          subscriber.error(MessageError.from(ErrorCode.RecaptchaLibFail))
          JEXCEL.loading = null
          JEXCEL.loadingSubscriber = null
        })
    })
    return JEXCEL.loading
  }
}
