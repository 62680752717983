import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ErrorCode, MessageError } from '@/model/error'
import app from '@/main'
import { State } from '@/store'

// const isProd = process.env.NODE_ENV === 'production'
const API_HOST = process.env.VUE_APP_API_HOST

export enum ResultCode {
  Error = 0,
  Success = 1,
  Login = 2
}

export interface ReturnError {
  code: number;
  message?: string;
}

export interface JsonReturnData<T> {
  /** ReturnData code */
  code: ResultCode;

  /** ReturnData data */
  data?: T;

  /** ReturnData error */
  error?: ReturnError;
}

class Http {
  private static _instance: Http | null = null

  static instance(): Http {
    if (Http._instance === null) {
      Http._instance = new Http()
    }
    return Http._instance
  }

  private constructor() {

  }

  // axios.defaults.withCredentials = true
  httpAxios = axios.create({
    withCredentials: true,
    headers: {
      common: {
        'X-V-API': process.env.VUE_APP_API_VERSION
      }
    }
  })

  requestAPI<T>(endpoint: string, request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      request.url = '/api/' + endpoint
    } else {
      request.url = API_HOST + endpoint
    }
    request.timeout = 30000
    return this.httpAxios(request).catch(e => {
      if (e.isAxiosError && e.response && (e.response.status === 401 || e.response.status === 403)) {
        return Promise.resolve(e.response)
      }
      if (e.isAxiosError && !e.response) {
        return Promise.reject(MessageError.from(ErrorCode.NoInternet, e))
      }
      return Promise.reject(e)
    })
  }

  requestAPIJson<T extends JsonReturnData<unknown>>(endpoint: string, request: AxiosRequestConfig): Promise<T> {
    const header = request.headers ?? {}
    if (!header.Accept) {
      header.Accept = 'application/json'
    }
    if (request.data && !header['Content-Type']) {
      header['Content-Type'] = 'application/json'
    }
    request.headers = header
    request.responseType = 'json'
    return this.requestAPI<T>(endpoint, request)
      .then(res => Http.defaultHandleAPIResponse(res))
  }

  static defaultHandleAPIResponse<T extends JsonReturnData<unknown>>(res: AxiosResponse<T>): Promise<T> {
    let loginError = false
    if (res.status === 200) {
      if (res.data?.code === ResultCode.Login) {
        loginError = true
      }
    } else if (res.status === 401 || res.status === 403) {
      loginError = true
    }
    if (loginError) {
      return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
    } else if (res.status !== 200) {
      return Promise.reject(Http._makeError(res))
    } else if (res.data?.code === ResultCode.Error) {
      return Promise.reject(Http.makeErrorResponse(res.data))
    }
    return Promise.resolve(res.data)
  }

  private static _makeError<T>(res: AxiosResponse<T>) {
    return MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText))
  }

  public static makeErrorResponse<T extends JsonReturnData<unknown>>(res?: T) {
    // if (res && res.code === ResultCode.Error && res.error?.code === Proto.ErrorCode.SERVER_MESSAGE && res.data) {
    //   /* eslint-disable @typescript-eslint/no-explicit-any */
    //   (window.Vue as any).$log.debug(res)
    //   let msg = ''
    //   if (res.data) {
    //     // if (util.isString(res.data)) {
    //     //   msg = res.data as string
    //     // } else {
    //     //   const arr = res.data as Uint8Array
    //     //   msg = util.utf8.read(arr, 0, arr.length)
    //     // }
    //   }
    //   return new MessageError(ErrorCode.ServerMessage, msg)
    // }
    if (res && res.code === ResultCode.Error) {
      if (res.error?.code === 8) {
        return MessageError.from(ErrorCode.UserResign)
      } else if (res.error?.code === 2001) {
        return MessageError.from(ErrorCode.DuplicatedPlan)
      } else if (res.error?.code === 2002) {
        return MessageError.from(ErrorCode.IsOwner)
      } else if (res.error?.code === 2006) {
        const error = MessageError.from(ErrorCode.PlanDependency)
        error.messageBlock = () => {
          return app.$i18n?.tc(`error.code.${ErrorCode.PlanDependency}`, undefined, { name: res.error?.message ?? '' }) ?? ''
        }
        return error
      } else if (res.error?.code === 2007) {
        return MessageError.from(ErrorCode.PlanUserLimitExceed)
      } else if (res.error?.code === 2008) {
        return MessageError.from(ErrorCode.PlanUserLimitExceed)
      } else if (res.error?.code === 2009) {
        return MessageError.from(ErrorCode.PlanApproveChange)
      } else if (res.error?.code === 5) {
        return MessageError.from(ErrorCode.NoContent)
      } else if (res.error?.code === 9004) {
        return MessageError.from(ErrorCode.ServerTrafficExceed)
      } else if (res.error?.code === 9501) {
        return MessageError.from(ErrorCode.RobotPaymentFail)
      } else if (res.error?.code === 9503) {
        return MessageError.from(ErrorCode.RobotPaymentBankFail)
      } else if (res.error?.code === 9504) {
        return MessageError.from(ErrorCode.PaymentRefundFail)
      } else if (res.error?.code === 9505) {
        return MessageError.from(ErrorCode.PaymentRefundDeliveredFail)
      } else if (res.error?.code === 9506) {
        return MessageError.from(ErrorCode.PaymentRefundFixedError)
      } else if (res.error?.code === 9507) {
        return MessageError.from(ErrorCode.PaymentMethodCapturedError)
      } else if (res.error?.code === 9508) {
        return MessageError.from(ErrorCode.FundingProjectEndError)
      } else if (res.error?.code === 9509) {
        return MessageError.from(ErrorCode.PaymentServiceEmptyError)
      } else if (res.error?.code === 9510) {
        const error = MessageError.from(ErrorCode.PaymentCancelError)
        error.messageBlock = () => {
          return res.error?.message || ''
        }
        return error
      } else if (res.error?.code === 9551) {
        return MessageError.from(ErrorCode.ProductQuantityFail)
      } else if (res.error?.code === 9601) {
        return MessageError.from(ErrorCode.RecaptchaError)
      } else if (res.error?.code === 9) {
        return MessageError.from(ErrorCode.ServerLoginBlock)
      } else if (res.error?.code === 10) {
        return MessageError.from(ErrorCode.ServerSecurityIP)
      } else if (res.error?.code === 11) {
        return MessageError.from(ErrorCode.ServerLoginDomain)
      } else if (res.error?.code === 2005) {
        const error = MessageError.from(ErrorCode.DomainBlock)
        error.messageBlock = () => {
          return app.$i18n?.tc(`error.code.${ErrorCode.DomainBlock}`, undefined, { name: res.error?.message ?? '' }) ?? ''
        }
        return error
      } else if (res.error?.code === 10000) {
        // app.$router?.replace({
        //   path: '/player/maintenance'
        // })
        const err = MessageError.from(ErrorCode.Maintenance, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
        err.meta = { data: res.error?.message ?? '' }
        return err
      }
    }
    return MessageError.from(ErrorCode.ServerInternal, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
  }

  public static randomUUID() {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = [] as string[]
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((parseInt(s[19], 10) & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'
    return s.join('')
  }
}

export default Http
