import i18n from '@/i18n'

export enum ErrorCode {
  ServerLoginFail = 1001,
  ServerLoginNeed = 1004,
  ServerLoginBlock,
  ServerMessage = 1090,
  ServerTrafficExceed = 1091,
  ServerTrafficExceedDownload = 1092,
  ServerTrafficExceedUpload = 1093,
  ServerSecurityIP = 1094,
  ServerLoginDomain = 1095,

  LinkTokenInvalid = 1101,

  NoDomain = 2001,
  NoDomainPublic = 2002,
  DomainBlock = 2003,
  UserResign = 2004,

  NoPlaylistMap = 3001,
  NoCommerceContentMap = 3002,

  NoInternet = 10001,
  ServerInternal = 10002,

  MovieLoadFailed = 20021,
  MovieIsNotMP4 = 20022,

  NoContent = 30001,
  NoPlaylistPlan = 30003,
  IsOwner = 30005,

  DuplicatedPlan = 41001,
  PlanDependency = 41002,
  PlanUserLimitExceed = 41003,
  PlanSignupBlocked = 41004,
  PlanApproveChange = 41005,

  RobotTokenFailed = 50001,
  RobotPaymentFail = 50002,
  RobotPaymentBankFail = 50003,

  ProductQuantityFail = 51001,
  PaymentRefundFail = 51002,
  PaymentRefundDeliveredFail = 51003,
  NoCommerceProduct = 51004,
  PaymentRefundFixedError = 51006,
  PaymentMethodCapturedError = 51007,
  FundingProjectEndError = 51008,
  PaymentServiceEmptyError = 51009,
  PaymentCancelError = 51010,
  FundingRefundFail = 52002,
  FundingRefundDeliveredFail = 52003,
  FundingRefundFixedError = 52006,
  TicketQuantityFail = 53001,
  TicketRefundFail = 53002,
  TicketRefundDeliveredFail = 53003,
  NoTicketProduct = 53004,
  TicketRefundFixedError = 53006,

  UserInterviewNoData = 70001,
  IntroFeatureNoData = 70002,

  NotAllowRegister = 80011,
  NotAllowRegisterForContent = 80012,

  FileLoadFailed = 95001,
  ImageLoadFailed = 95002,
  PDFLoadFailed = 95003,

  RecaptchaError = 96001,

  UpdatePasswordFail = 97001,

  RecaptchaLibFail = 99997,
  Unexpected = 99998,
  Cancelled = 99999,
  Maintenance = 100000
}

export class MessageError extends Error {
  public static Unexpected = MessageError.from(ErrorCode.Unexpected)
  public static Cancelled = MessageError.from(ErrorCode.Cancelled)

  code: ErrorCode
  msgKey: string
  error?: Error
  messageBlock?: () => string
  meta?: { [key: string]: unknown }

  static from(code: ErrorCode, error?: Error | DOMException | null) {
    if (error && error instanceof DOMException) {
      return new MessageError(code, 'error.code.' + code, new Error(error.message))
    }
    return new MessageError(code, 'error.code.' + code, error)
  }

  constructor(code: ErrorCode, msg: string, error?: Error | null) {
    let errMsg = ''
    if (error) {
      errMsg = ', err: ' + error.message
    }
    super(`code: ${code}, msg: ${msg}${errMsg}`)
    this.name = error ? error.name : code.toString()
    this.code = code
    this.msgKey = msg
    if (error !== null) {
      this.error = error
    }
  }

  getMessage() {
    if (this.messageBlock) {
      return this.messageBlock()
    }
    if (this.msgKey) {
      let err = i18n.tc(this.msgKey)
      if (!err) {
        err = i18n.tc('error.code.' + this.code)
        if (err) {
          return err
        }
      } else {
        return err
      }
    } else {
      const err = i18n.tc('error.code.' + this.code)
      if (err) {
        return err
      }
    }
    return i18n.tc('error.other')
  }
}
