import i18n from '@/i18n'

export enum ErrorCode {
  ServerLoginFail = 1001,
  ServerLoginNeed = 1004,
  ServerLoginBlock,
  ServerMessage = 1090,
  ServerTrafficExceed = 1091,
  ServerTrafficExceedDownload = 1092,
  ServerStorageExceed = 1093,
  ServerSecurityIP = 1094,
  ServerLoginDomain = 1095,

  MultipleAccount = 2000,
  UserResign = 2004,

  NoInternet = 10001,
  ServerInternal = 10002,

  NoContent = 30001,
  NoContentPublic = 30002,
  NoPlaylistPlan = 30003,
  IsOwner = 30005,
  IsUser = 30006,
  BlockAdmin = 30007,
  NoUserGroup = 30011,

  NoZoom = 30091,
  NoZoomLive = 30092,
  NoByteLive = 30093,
  LiveUserLimit = 30099,

  NoChatroom = 32001,
  NoChat = 32002,
  NoSigningContent = 33001,

  PollDuplicate = 34001,

  PDFLibraryError = 35001,

  NoWorkflow = 36001,
  NoWorkflowAccess = 36002,
  WorkflowDuplicate = 36003,
  WorkflowFileDuplicate = 36004,
  NoWorkflowFileTemplate = 36005,

  DuplicatedPlan = 41001,
  PlanDependency = 41002,
  PlanUserLimitExceed = 41003,
  PlanSignupBlocked = 41004,

  GroupChatJoinedDuplicated = 50001,
  GroupChatPasswordInvalid = 50002,
  GroupChatNoJoined = 50003,
  GroupChatNotExclusiveUser = 50004,
  GroupChatNotOwner = 50005,
  GroupChatUserNotJoined = 50006,
  NoForum = 50101,
  NoTopic = 50102,

  ProductQuantityFail = 51001,
  PaymentRefundFail = 51002,
  PaymentRefundDeliveredFail = 51003,
  NoCommerceProduct = 51004,
  PaymentRefundFixedError = 51006,
  PaymentMethodCapturedError = 51007,
  FundingProjectEndError = 51008,
  PaymentServiceEmptyError = 51009,
  PaymentCancelPolicyError = 51010,
  FundingRefundFail = 52002,
  FundingRefundDeliveredFail = 52003,
  FundingRefundFixedError = 52006,
  TicketQuantityFail = 53001,
  TicketRefundFail = 53002,
  TicketRefundDeliveredFail = 53003,
  NoTicketProduct = 53004,
  TicketRefundFixedError = 53006,
  TicketDuplicatedError = 53010,
  TicketNoStaffError = 53011,

  WorkNoStaff = 60001,

  NoThirdOAuthUser = 80001,
  NotAllowRegister = 80011,
  NotAllowRegisterForContent = 80012,

  FileLoadFailed = 95001,
  ImageLoadFailed = 95002,
  PDFLoadFailed = 95003,
  ImageExtensionFailed = 95004,

  DBOpenFail = 96001,
  DBInsertFail = 96002,
  DBFindFail = 96003,

  JoinMaxUser = 90001,
  UserBlock = 90005,
  LPStop = 90006,

  PlayerFail = 92002,

  NotSupported = 95001,

  RecaptchaError = 96001,
  MaxDeviceError = 97001,
  ScheduleMaxUserError = 97002,
  ScheduleDuplicatedError = 97003,

  FileUpload = 99995,
  FileDownload = 99996,
  RecaptchaLibFail = 99997,
  Unexpected = 99998,
  Cancelled = 99999,
  Maintenance = 100000
}

export class MessageError extends Error {
  public static Unexpected = MessageError.from(ErrorCode.Unexpected)
  public static Cancelled = MessageError.from(ErrorCode.Cancelled)

  code: ErrorCode
  msgKey: string
  error?: Error
  messageBlock?: () => string
  meta?: { [key: string]: unknown }
  protoCode?: number

  static from(code: ErrorCode, error?: Error | DOMException | null, protoCode?: number | null) {
    if (error && error instanceof DOMException) {
      return new MessageError(code, 'player.error.code.' + code, new Error(error.message), protoCode)
    }
    return new MessageError(code, 'player.error.code.' + code, error, protoCode)
  }

  constructor(code: ErrorCode, msg: string, error?: Error | null, protoCode?: number | null) {
    let errMsg = ''
    if (error) {
      errMsg = ', err: ' + error.message
    }
    super(`code: ${code}, msg: ${msg}${errMsg}`)
    this.name = error ? error.name : code.toString()
    this.code = code
    this.msgKey = msg
    if (error !== null) {
      this.error = error
    }
    this.protoCode = protoCode !== null ? protoCode : undefined
  }

  getMessage() {
    if (this.messageBlock) {
      return this.messageBlock()
    }
    if (this.msgKey) {
      if (this.code === ErrorCode.ServerMessage) {
        return this.msgKey
      }
      let err = i18n.tc(this.msgKey)
      if (!err) {
        err = i18n.tc('player.error.code.' + this.code)
        if (err) {
          return err
        }
      } else {
        return err
      }
    } else {
      const err = i18n.tc('player.error.code.' + this.code)
      if (err) {
        return err
      }
    }
    return i18n.tc('player.error.other')
  }
}
