import app from '@/main'
import * as UAParser from 'ua-parser-js'
import { ContentType } from '@/player/model/constant'
import { PlayerUIService } from '@/player/service/ui.service'
import { getBaseUrl } from '@/router'

export class BrowserService {
  public static isIE(): boolean {
    return /Trident\/|MSIE /.test(window.navigator.userAgent)
  }

  public static isSupported(): boolean {
    if (BrowserService.isMobileDevice() || BrowserService.isTabletDevice()) {
      const parser = new UAParser().getResult()
      try {
        if (parser.os.name.toLowerCase() === 'ios') {
          return parseInt(parser.os.version.split('.')[0], 10) >= 11
        } else if (parser.os.name.toLowerCase().startsWith('android')) {
          return parseInt(parser.os.version.split('.')[0], 10) >= 5
        }
      } catch (e) {
        app.$log?.error(e)
        return false
      }
    }
    const video = document.createElement('video')
    return video.canPlayType('video/mp4; codecs="avc1.4d001f"') === 'probably'
  }

  public static isFirefox(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('firefox') >= 0
  }

  public static isSafari(): boolean {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return Object.prototype.toString.call((window as any).HTMLElement).indexOf('Constructor') > 0 || (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
      /* eslint-disable @typescript-eslint/no-explicit-any */
    })(!(window as any).safari || (window as any).safari.pushNotification)
  }

  public static supportSafariGalleryView(): boolean {
    const parser = new UAParser().getResult()
    try {
      /* eslint-disable no-console */
      console.error(parser)
      if (parser.browser.name.toLowerCase() === 'safari' && parser.os.name.toLowerCase() === 'mac os') {
        const osVersion1 = parseInt(parser.os.version.split('.')[0], 10)
        const osVersion2 = parseInt(parser.os.version.split('.')[1], 10)
        return parseInt(parser.browser.version.split('.')[0], 10) >= 17 && (osVersion1 >= 11 || (osVersion1 === 10 && osVersion2 >= 15))
      }
      return false
    } catch (e) {
      app.$log?.error(e)
      return false
    }
  }

  public static isCrawler(): boolean {
    try {
      return /(Googlebot|Prerender|bingbot|yahoou|baiduspider|yandex|yeti|yodaobot|gigabot|ia_archiver|facebookexternalhit|twitterbot|Discordbot|SkypeUriPreview|PlurkBot|Slackbot|Twitterbot|Linespider|Slack|bot)/ig.test(navigator.userAgent)
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e)
      return false
    }
  }

  public static isPC(): boolean {
    const parser = new UAParser().getResult()
    return !parser.device.type && !BrowserService.isIOS() && !BrowserService.isAndroid()
  }

  public static isMobileDevice(): boolean {
    const parser = new UAParser().getResult()
    return parser.device?.type === 'mobile'
  }

  public static isTabletDevice(): boolean {
    const parser = new UAParser().getResult()
    return parser.device?.type === 'tablet'
  }

  public static isIOS(): boolean {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || BrowserService.isIpadOS()
  }

  public static isIpadOS(): boolean {
    return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
  }

  public static isIpad(): boolean {
    return /iPad/.test(navigator.userAgent) || BrowserService.isIpadOS()
  }

  public static isAndroid(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1
  }

  public static isAndroidTablet(): boolean {
    return this.isAndroid() && ((window.outerWidth >= 600 && window.outerHeight >= 480) || window.outerWidth >= 896)
  }

  public static isChromium(): boolean {
    if ((window as any).chrome) {
      return true
    }
    if ((navigator as any).userAgentData && (navigator as any).userAgentData.brands) {
      for (const pair of (navigator as any).userAgentData.brands) {
        if (pair.brand === 'Chromium') {
          return true
        }
      }
    }
    return false
  }

  public static isInIframe(defaultValue?: boolean): boolean {
    try {
      return window.self !== window.top
    } catch (e) {
      return defaultValue ?? false
    }
  }

  public static encodeHtml(string: string): string {
    try {
      const body = string || ''
      return body.replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    } catch (e) {
      return string
    }
  }

  public static isEmeSupported(): Promise<boolean> {
    // EME Check
    const keySystems = {
      // widevine: ['com.widevine.alpha'],
      // playready: ['com.microsoft.playready', 'com.youtube.playready'],
      clearkey: ['webkit-org.w3.clearkey', 'org.w3.clearkey']
      // primetime: ['com.adobe.primetime', 'com.adobe.access'],
      // fairplay: ['com.apple.fairplay']
    }

    const testVideoElement = document.createElement('video') as any
    const supportedSystems: string[] = []

    let hasEME = false
    const promises: Promise<void>[] = []
    if (!testVideoElement.mediaKeys) {
      if (window.navigator.requestMediaKeySystemAccess) {
        if (typeof window.navigator.requestMediaKeySystemAccess === 'function') {
          // found default EME
          hasEME = true
          const isKeySystemSupported = (keySystem: string) => {
            const config = [{
              initDataTypes: ['cenc'],
              videoCapabilities: [{
                contentType: 'video/mp4;codecs="avc1.42E01E"'
              }]
            }]
            if (window.navigator.requestMediaKeySystemAccess) {
              promises.push(window.navigator.requestMediaKeySystemAccess(keySystem, config)
                .then(function (_) {
                  supportedSystems.push(keySystem)
                })
                .catch(function () {
                }))
            }
          }
          for (const keysys in keySystems) {
            const keys = keySystems[keysys]
            for (const drm of keys) {
              isKeySystemSupported(drm)
            }
          }
        }
      } else if (window.MSMediaKeys) {
        if (typeof window.MSMediaKeys === 'function') {
          // found MS-EME
          hasEME = true
          for (const keysys in keySystems) {
            const keys = keySystems[keysys]
            for (const drm of keys) {
              if (window.MSMediaKeys.isTypeSupported('video/mp4', drm)) {
                supportedSystems.push(drm)
              }
            }
          }
        }
      } else if (testVideoElement.webkitGenerateKeyRequest) {
        if (typeof testVideoElement.webkitGenerateKeyRequest === 'function') {
          // found WebKit EME
          hasEME = true
          for (const keysys in keySystems) {
            const keys = keySystems[keysys]
            for (const drm of keys) {
              if (testVideoElement.canPlayType('video/mp4', drm)) {
                supportedSystems.push(drm)
              }
            }
          }
        }
      } else {
        // no supported EME implementation found
        hasEME = false
      }
      const p: Promise<unknown> = promises.length > 0 ? Promise.all(promises) : Promise.resolve()
      return p
        .then((): boolean => {
          return hasEME && (supportedSystems.indexOf('webkit-org.w3.clearkey') >= 0 || supportedSystems.indexOf('org.w3.clearkey') >= 0)
        })
        .catch(() => false)
    }
    return Promise.resolve(false)
  }

  public static dispatchEvent(elm: EventTarget = window, event: string, dict?: EventInit, eventInterface?: string) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if ((document as any).documentMode) {
      /* eslint-disable no-unneeded-ternary */
      const eve = document.createEvent(eventInterface ? eventInterface : 'Event')
      eve.initEvent(event, dict && dict.bubbles, dict && dict.cancelable)
      elm.dispatchEvent(eve)
    } else {
      elm.dispatchEvent(new Event(event, dict))
    }
  }

  public static shouldAsyncLoadByScroll(target: HTMLElement | Document): boolean {
    if (target === document) {
      const range = window.scrollY / (document.documentElement.scrollHeight - document.documentElement.offsetHeight)
      return range > 0.98
    } else {
      const tag = target as HTMLElement
      if (tag.scrollTop) {
        const range = tag.scrollTop / (tag.scrollHeight - tag.offsetHeight)
        return range > 0.98
      }
    }
    return false
  }

  public static fullscreen(fullscreen: boolean, el: HTMLElement): Promise<boolean> {
    try {
      let promise: Promise<boolean> | undefined
      if (fullscreen) {
        let element = el as any
        if (!element) {
          element = document.documentElement
        }
        if (element) {
          if (element.requestFullscreen) {
            promise = element.requestFullscreen()
          } else if (element.mozRequestFullScreen) {
            promise = element.mozRequestFullScreen()
          } else if (element.webkitRequestFullScreen) {
            promise = element.webkitRequestFullScreen()
          } else if (element.msRequestFullscreen) {
            promise = element.msRequestFullscreen()
          } else if (element.webkitEnterFullscreen) {
            promise = element.webkitEnterFullscreen()
          }
        }
        if (!promise && el && el.nodeName !== 'video') {
          const video = el.querySelector('video')
          if (video) {
            return BrowserService.fullscreen(fullscreen, video)
          }
        }
      } else {
        const element = document as any
        if (element.exitFullscreen) {
          promise = element.exitFullscreen()
        } else if (element.webkitExitFullscreen) {
          promise = element.webkitExitFullscreen()
        } else if (element.mozCancelFullScreen) {
          promise = element.mozCancelFullScreen()
        } else if (element.msExitFullscreen) {
          promise = element.msExitFullscreen()
        }
      }
      if (promise && promise.then) {
        return promise
          .then(() => true)
          .catch(e => {
            app.$log.error(e)
            return false
          })
      }
      return Promise.resolve(true)
    } catch (e) {
      app.$log.error(e)
      return Promise.resolve(false)
    }
  }

  public static copyToClipboard(text: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowAny = window as any
    if (windowAny.clipboardData && windowAny.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      windowAny.clipboardData.setData('Text', text)
      return true
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea')
      textarea.textContent = text
      textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand('copy') // Security exception may be thrown by some browsers.
        return true
      } catch (ex) {
        app.$log?.error('Copy to clipboard failed.', ex)
        return false
      } finally {
        document.body.removeChild(textarea)
      }
    }
    return false
  }

  public static createMetaTag(property: string, content: string): HTMLMetaElement {
    const tag = document.createElement('meta')
    tag.setAttribute('property', property)
    tag.content = content
    return tag
  }

  public static createMetaNameTag(name: string, content: string): HTMLMetaElement {
    const tag = document.createElement('meta')
    tag.name = name
    tag.content = content
    return tag
  }

  public static metaTag(playlistUID: string, content: any, template?: any, isBtoB?: boolean) {
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const contentPath = PlayerUIService.contentPath(playlistUID, content)
      const url = window.location.origin + getBaseUrl() + contentPath.substring(1)
      const suffix = isBtoB ? '' : ' | LITEVIEW'
      const title = template ? `${content.title} | ${template.name}${suffix}` : `${content.title}${suffix}`
      document.title = title
      const tags = [
        BrowserService.createMetaTag('og:url', url),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:site_name', template?.name ?? 'LITEVIEW'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title)
      ]
      const tmp = document.createElement('DIV')
      if (ContentType.isEvent(content.type as ContentType) && content.events && content.events.length > 0) {
        const event = content.events[0]
        tmp.innerHTML = event.description || event.title || ''
      } else {
        tmp.innerHTML = content.description || ''
      }
      const description = (tmp.textContent || tmp.innerText || '').replace('/\n/g', ' ')
      if (description) {
        tags.push(BrowserService.createMetaTag('og:description', description))
        tags.push(BrowserService.createMetaNameTag('description', description))
      }
      if (content.file && content.file.thumbnailImageURL && content.file.thumbnailUploaded && content.file.thumbnailUploaded.value) {
        tags.push(...[
          BrowserService.createMetaTag('og:image', content.file.thumbnailImageURL),
          BrowserService.createMetaNameTag('twitter:image', content.file.thumbnailImageURL)
        ])
        if (content.file.width && content.file.height) {
          tags.push(...[
            BrowserService.createMetaTag('og:image:width', content.file.width.toString(10)),
            BrowserService.createMetaTag('og:image:height', content.file.height.toString(10))
          ])
        }
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static metaTagEC(commerce: any, playlistUID: string, contentUID: string, template?: any, isBtoB?: boolean) {
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const url = window.location.origin + getBaseUrl() + `player/product/commerce?uid=${commerce.uid}&puid=${playlistUID}&cuid=${contentUID}`
      const suffix = isBtoB ? '' : ' | LITEVIEW'
      const title = template ? `${commerce.title} | ${template.name}${suffix}` : `${commerce.title}${suffix}`
      document.title = title
      const tags = [
        BrowserService.createMetaTag('og:url', url),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:site_name', template?.name ?? 'LITEVIEW'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title)
      ]
      const tmp = document.createElement('DIV')
      tmp.innerHTML = commerce.description || ''
      const description = (tmp.textContent || tmp.innerText || '').replace('/\n/g', ' ')
      if (description) {
        tags.push(BrowserService.createMetaTag('og:description', description))
        tags.push(BrowserService.createMetaNameTag('description', description))
      }
      if (commerce.image && commerce.image.thumbnailImageURL && commerce.image.thumbnailUploaded && commerce.image.thumbnailUploaded.value) {
        tags.push(...[
          BrowserService.createMetaTag('og:image', commerce.image.thumbnailImageURL),
          BrowserService.createMetaNameTag('twitter:image', commerce.image.thumbnailImageURL)
        ])
        if (commerce.image.width && commerce.image.height) {
          tags.push(...[
            BrowserService.createMetaTag('og:image:width', commerce.image.width.toString(10)),
            BrowserService.createMetaTag('og:image:height', commerce.image.height.toString(10))
          ])
        }
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static metaTagFunding(funding: any, playlistUID: string, contentUID: string, template?: any, isBtoB?: boolean) {
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const url = window.location.origin + getBaseUrl() + `player/product/funding?uid=${funding.uid}&puid=${playlistUID}&cuid=${contentUID}`
      const suffix = isBtoB ? '' : ' | LITEVIEW'
      const title = template ? `${funding.title} | ${template.name}${suffix}` : `${funding.title}${suffix}`
      document.title = title
      const tags = [
        BrowserService.createMetaTag('og:url', url),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:site_name', template?.name ?? 'LITEVIEW'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title)
      ]
      const tmp = document.createElement('DIV')
      tmp.innerHTML = funding.description || ''
      const description = (tmp.textContent || tmp.innerText || '').replace('/\n/g', ' ')
      if (description) {
        tags.push(BrowserService.createMetaTag('og:description', description))
        tags.push(BrowserService.createMetaNameTag('description', description))
      }
      if (funding.image && funding.image.thumbnailImageURL && funding.image.thumbnailUploaded && funding.image.thumbnailUploaded.value) {
        tags.push(...[
          BrowserService.createMetaTag('og:image', funding.image.thumbnailImageURL),
          BrowserService.createMetaNameTag('twitter:image', funding.image.thumbnailImageURL)
        ])
        if (funding.image.width && funding.image.height) {
          tags.push(...[
            BrowserService.createMetaTag('og:image:width', funding.image.width.toString(10)),
            BrowserService.createMetaTag('og:image:height', funding.image.height.toString(10))
          ])
        }
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static metaTagTicket(ticket: any, playlistUID: string, contentUID: string, template?: any, isBtoB?: boolean) {
    try {
      document.querySelectorAll('meta[name="robots"]')
        .forEach(tag => tag.remove())
      document.querySelectorAll('meta[name="googlebot"]')
        .forEach(tag => tag.remove())
      const keys = ['og:url', 'og:title', 'og:description', 'og:site_name', 'og:image', 'og:image:width', 'og:image:height']
      keys.forEach(key => {
        document.querySelectorAll(`meta[property="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const nameKeys = ['description', 'twitter:card', 'twitter:title', 'twitter:description', 'twitter:image']
      nameKeys.forEach(key => {
        document.querySelectorAll(`meta[name="${key}"]`)
          .forEach(tag => tag.remove())
      })
      const url = window.location.origin + getBaseUrl() + `player/product/ticket?uid=${ticket.uid}&puid=${playlistUID}&cuid=${contentUID}`
      const suffix = isBtoB ? '' : ' | LITEVIEW'
      const title = template ? `${ticket.title} | ${template.name}${suffix}` : `${ticket.title}${suffix}`
      document.title = title
      const tags = [
        BrowserService.createMetaTag('og:url', url),
        BrowserService.createMetaTag('og:title', title),
        BrowserService.createMetaTag('og:site_name', template?.name ?? 'LITEVIEW'),
        BrowserService.createMetaNameTag('twitter:card', 'summary'),
        BrowserService.createMetaNameTag('twitter:title', title)
      ]
      const tmp = document.createElement('DIV')
      tmp.innerHTML = ticket.description || ''
      const description = (tmp.textContent || tmp.innerText || '').replace('/\n/g', ' ')
      if (description) {
        tags.push(BrowserService.createMetaTag('og:description', description))
        tags.push(BrowserService.createMetaNameTag('description', description))
      }
      if (ticket.image && ticket.image.thumbnailImageURL && ticket.image.thumbnailUploaded && ticket.image.thumbnailUploaded.value) {
        tags.push(...[
          BrowserService.createMetaTag('og:image', ticket.image.thumbnailImageURL),
          BrowserService.createMetaNameTag('twitter:image', ticket.image.thumbnailImageURL)
        ])
        if (ticket.image.width && ticket.image.height) {
          tags.push(...[
            BrowserService.createMetaTag('og:image:width', ticket.image.width.toString(10)),
            BrowserService.createMetaTag('og:image:height', ticket.image.height.toString(10))
          ])
        }
      }
      document.head.append(...tags)
    } catch (e) {
      app.$log?.error(e)
    }
  }

  public static openUrl(url: string) {
    if (BrowserService.isMobileDevice()) {
      window.location.href = url
    } else {
      window.open(url)
    }
  }
}
