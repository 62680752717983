import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import i18n, { i18nUtil } from '@/i18n'
import {
  BoardActionPayload,
  BtoBLogoActionPayload,
  CrawlerErrorActionPayload,
  LocaleActionPayload,
  LogoActionPayload,
  NeedMainLoginActionPayload,
  PageUIDActionPayload,
  SubdomainActionPayload,
  TemplateActionPayload
} from '@/store/meta/action'
import store, { State } from '@/store'
import { Location, Position, PositionResult, RawLocation } from 'vue-router/types/router'
import app from '../main'
import { isString } from '@/model/constant'
import { APIService } from '@/service/api.service'
import { PlayerApiService } from '@/player/service/api.service'
import {
  PlayerChannelIOActionPayload,
  PlayerLogoActionPayload,
  PlayerSubdomainActionPayload,
  PlayerTemplateActionPayload
} from '@/store/player/meta/action'
import AlertModal from '@/components/modal/AlertModal.vue'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { DateService } from '@/player/service/date.service'
import { Observable, of } from 'rxjs'
import { ErrorCode, MessageError } from '@/model/error'
import { Proto } from '@/player/model/proto'
import { fromPromise } from 'rxjs/internal-compatibility'
import { PlayerStoreService } from '@/player/service/store.service'
import { StoreKey } from '@/player/model/constant'
import { BrowserService } from '@/service/browser.service'
import { ChannelIOService } from '@/player/service/channel.io.service'
import ConfirmModal from '@/components/modal/ConfirmModal.vue'
import { SiteDesignPageType } from '@/model/template'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/order',
    meta: {
      hideChannelIO: true
    },
    props: true,
    component: () => import(/* webpackChunkName: "ordermain" */ '@/views/order/OrderMain.vue'),
    children: [{
      path: '',
      props: true,
      meta: {
        useTemplate: true
      },
      component: () => import(/* webpackChunkName: "orderindex" */ '@/views/order/OrderIndex.vue'),
      children: []
    }, {
      path: 'finish',
      props: true,
      meta: {
        useTemplate: true
      },
      component: () => import(/* webpackChunkName: "orderdone" */ '@/views/order/OrderDone.vue'),
      children: []
    }, {
      path: 'product/login',
      props: true,
      meta: {
        useTemplate: true
      },
      component: () => import(/* webpackChunkName: "orderlogin" */ '@/views/order/OrderLogin.vue'),
      children: []
    }, {
      path: 'product/done',
      props: true,
      component: () => import(/* webpackChunkName: "productdone" */ '@/views/order/OrderProductDone.vue'),
      children: []
    }, {
      path: 'product/:productUID',
      props: true,
      meta: {
        requiresAuth: true,
        useTemplate: true
      },
      component: () => import(/* webpackChunkName: "product" */ '@/views/order/OrderProduct.vue'),
      children: []
    }, {
      path: 'funding/done',
      props: true,
      component: () => import(/* webpackChunkName: "fundingdone" */ '@/views/order/OrderFundingDone.vue'),
      children: []
    }, {
      path: 'funding/:variationUID',
      props: true,
      meta: {
        requiresAuth: true,
        useTemplate: true
      },
      component: () => import(/* webpackChunkName: "orderfunding" */ '@/views/order/OrderFunding.vue'),
      children: []
    }, {
      path: 'ticket/login',
      props: true,
      meta: {
        useTemplate: true
      },
      component: () => import(/* webpackChunkName: "orderlogin" */ '@/views/order/OrderLogin.vue'),
      children: []
    }, {
      path: 'ticket/done',
      props: true,
      component: () => import(/* webpackChunkName: "ticketdone" */ '@/views/order/OrderTicketDone.vue'),
      children: []
    }, {
      path: 'ticket/:dateUserUID',
      props: true,
      meta: {
        requiresAuth: true,
        useTemplate: true
      },
      component: () => import(/* webpackChunkName: "orderticket" */ '@/views/order/OrderTicket.vue'),
      children: []
    }]
  },
  {
    path: '/player',
    component: () => import(/* webpackChunkName: "videomain" */ '@/player/views/video/VideoMain.vue'),
    meta: {
      useTemplate: true,
      requiresLoginFirst: true
    },
    children: [{
      path: 'playlist/:uid',
      props: true,
      component: () => import(/* webpackChunkName: "playlist" */ '@/player/views/video/Playlist.vue')
    }, {
      path: 'playlist/:uid/video/:cuid',
      props: (route) => ({
        playlistUID: route.params.uid,
        contentUID: route.params.cuid
      }),
      meta: {
        fullWidthFooter: '1'
      },
      component: () => import(/* webpackChunkName: "videoplayer" */ '@/player/views/video/VideoPlayer.vue')
    }, {
      path: 'playlist/:uid/event/:cuid',
      props: (route) => ({
        playlistUID: route.params.uid,
        contentUID: route.params.cuid
      }),
      component: () => import(/* webpackChunkName: "event" */ '@/player/views/video/Event.vue')
    }, {
      path: 'stock/news/:newsId',
      props: (route) => ({
        newsId: route.params.newsId,
        type: route.query.type,
        jcode: route.query.jcode,
        date: route.query.date
      }),
      component: () => import(/* webpackChunkName: "quicknews" */ '@/player/views/quick/News.vue')
    }, {
      path: 'stock/news',
      props: (route) => ({
        type: route.query.type,
        dataJson: route.query.j
      }),
      component: () => import(/* webpackChunkName: "quicknewsprograms" */ '@/player/views/quick/NewsPrograms.vue')
    }, {
      path: 'stock/search',
      component: () => import(/* webpackChunkName: "quicksearch" */ '@/player/views/quick/Search.vue')
    }, {
      path: 'product/commerce',
      props: (route) => ({
        uid: route.query.uid,
        playlistUID: route.query.puid,
        contentUID: route.query.cuid
      }),
      component: () => import(/* webpackChunkName: "productcommerce" */ '@/player/views/product/Commerce.vue')
    }, {
      path: 'product/funding',
      props: (route) => ({
        uid: route.query.uid,
        playlistUID: route.query.puid,
        contentUID: route.query.cuid
      }),
      component: () => import(/* webpackChunkName: "productfunding" */ '@/player/views/product/Funding.vue')
    }, {
      path: 'product/ticket',
      props: (route) => ({
        uid: route.query.uid,
        playlistUID: route.query.puid,
        contentUID: route.query.cuid
      }),
      component: () => import(/* webpackChunkName: "productticket" */ '@/player/views/product/Ticket.vue')
    }]
  }, {
    path: '/player/zoom',
    props: (route) => ({
      contentUID: route.query.cuid,
      eventUID: route.query.euid,
      dateUID: route.query.eduid,
      name: route.query.name,
      leave: route.query.leave,
      leaved: route.query.leaved
    }),
    meta: {
      useTemplate: true,
      hideChannelIO: true
    },
    component: () => import(/* webpackChunkName: "zoom" */ '@/player/views/video/Zoom.vue')
  }, {
    path: '/player/admin/live/:uid/:cuid',
    props: (route) => ({
      playlistUID: route.params.uid,
      contentUID: route.params.cuid
    }),
    meta: {
      useTemplate: true,
      hideChannelIO: true
    },
    component: () => import(/* webpackChunkName: "livestreaming" */ '@/player/views/LiveStreaming.vue')
  }, {
    path: '/lp',
    redirect: (to) => {
      return {
        path: to.path.replace('/lp', '/creator'),
        query: to.query,
        hash: to.hash
      }
    }
  }, {
    path: '/lp/*',
    redirect: (to) => {
      return {
        path: to.path.replace('/lp', '/creator'),
        query: to.query,
        hash: to.hash
      }
    }
  }, {
    path: '/creator',
    component: () => import(/* webpackChunkName: "Corporate" */ '@/corporate/Main.vue'),
    children: [{
      path: '',
      component: () => import(/* webpackChunkName: "CorporateHome" */ '@/corporate/Home.vue')
    }, {
      path: 'service',
      component: () => import(/* webpackChunkName: "corporateService" */ '@/corporate/Service.vue')
    }, {
      path: 'case',
      component: () => import(/* webpackChunkName: "corporateCase" */ '@/corporate/Case.vue')
    }, {
      path: 'story',
      component: () => import(/* webpackChunkName: "corporateStory" */ '@/corporate/Story.vue')
    }, {
      path: 'plan',
      component: () => import(/* webpackChunkName: "corporatePlan" */ '@/corporate/Plan.vue')
    }, {
      path: 'planDetail',
      component: () => import(/* webpackChunkName: "CorporatePlanDetail" */ '@/corporate/PlanDetail.vue')
    }, {
      path: 'function',
      props: true,
      // component: () => import(/* webpackChunkName: "CorporateFunctionList" */ '@/corporate/FunctionList.vue')
      component: () => import(/* webpackChunkName: "CorporateBizFunction" */ '@/corporate-biz/Function.vue')
      // }, {
      //   path: 'function/:uid',
      //   props: true,
      //   component: () => import(/* webpackChunkName: "CorporateFunctionDetail" */ '@/corporate/FunctionDetail.vue')
    }, {
      path: 'instance',
      props: true,
      component: () => import(/* webpackChunkName: "CorporateInstance" */ '@/corporate/Instance.vue')
    }, {
      path: 'interview',
      props: true,
      component: () => import(/* webpackChunkName: "userinterviewList" */ '@/corporate/UserInterviewList.vue')
    }, {
      path: 'interview/:uid',
      props: true,
      component: () => import(/* webpackChunkName: "userinterview" */ '@/corporate/UserInterview.vue')
    }]
  }, {
    path: '/player/maintenance',
    component: () => import(/* webpackChunkName: "Maintenance" */ '@/player/views/Maintenance.vue')
  }, {
    path: '/404',
    meta: {
      useTemplate: true
    },
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/404.vue')
  }, {
    path: '/app/poll',
    component: () => import(/* webpackChunkName: "AppPoll" */ '@/views/app/AppPoll.vue'),
    meta: {
      hideChannelIO: true
    }
  }, {
    path: '/login',
    meta: {
      useTemplate: true
    },
    component: () => import(/* webpackChunkName: "Login" */ '@/views/login/LoginPage.vue')
  },
  {
    path: '/',
    name: 'root',
    component: () => import(/* webpackChunkName: "root" */ '@/views/Root.vue'),
    meta: {
      useTemplate: true,
      requiresLoginFirst: true
    },
    children: [
      {
        path: 'business',
        meta: {
          useTemplate: false,
          requiresLoginFirst: false
        },
        component: () => import(/* webpackChunkName: "CorporateBizRouter" */ '@/corporate-biz/Router.vue'),
        children: [{
          path: 'function',
          component: () => import(/* webpackChunkName: "CorporateBizFunction" */ '@/corporate-biz/Function.vue')
        }, {
          path: 'case',
          component: () => import(/* webpackChunkName: "CorporateBizCase" */ '@/corporate-biz/Case.vue')
        }, {
          path: 'case/:uid',
          props: true,
          component: () => import(/* webpackChunkName: "userinterview" */ '@/corporate-biz/UserInterview.vue')
        }, {
          path: 'plan',
          redirect: '/#plan'
        }, {
          path: 'consulting',
          component: () => import(/* webpackChunkName: "CorporateBizConsulting" */ '@/corporate-biz/Consulting.vue')
        }, {
          path: 'contact',
          component: () => import(/* webpackChunkName: "CorporateBizContact" */ '@/corporate-biz/Contact.vue')
        }, {
          path: 'document',
          component: () => import(/* webpackChunkName: "CorporateBizDocument" */ '@/corporate-biz/Document.vue')
        }, {
          path: 'team',
          component: () => import(/* webpackChunkName: "CorporateBizTeam" */ '@/corporate-biz/Team.vue')
        }]
      },
      {
        path: 'programs',
        meta: {
          pageType: SiteDesignPageType.ContentList
        },
        component: () => import(/* webpackChunkName: "programs" */ '@/views/Programs.vue')
      }, {
        path: 'forum',
        meta: {
          pageType: SiteDesignPageType.ForumList
        },
        component: () => import(/* webpackChunkName: "forum" */ '@/views/Forum.vue'),
        children: [
          {
            path: '/',
            props: true,
            meta: {
              pageType: SiteDesignPageType.ForumList
            },
            components: {
              nav: () => import(/* webpackChunkName: "forumlist" */ '@/views/ForumList.vue'),
              body: () => import(/* webpackChunkName: "forumcommentlist" */ '@/views/ForumCommentList.vue')
            }
          },
          {
            path: ':uid',
            props: true,
            meta: {
              pageType: SiteDesignPageType.ForumList
            },
            components: {
              nav: () => import(/* webpackChunkName: "forumtopiclist" */ '@/views/ForumTopicList.vue'),
              body: () => import(/* webpackChunkName: "forumcommentlist" */ '@/views/ForumCommentList.vue')
            }
          },
          {
            path: ':uid/:tuid',
            props: true,
            meta: {
              pageType: SiteDesignPageType.ForumList
            },
            components: {
              nav: () => import(/* webpackChunkName: "forumtopiclist" */ '@/views/ForumTopicList.vue'),
              body: () => import(/* webpackChunkName: "forumcommentlist" */ '@/views/ForumCommentList.vue')
            }
          }
        ]
      }, {
        path: 'chatroom',
        meta: {
          pageType: SiteDesignPageType.Chatroom
        },
        component: () => import(/* webpackChunkName: "chatroom" */ '@/views/Chatroom.vue'),
        children: [
          {
            path: 'chat/:uid',
            props: true,
            meta: {
              dm: true,
              admin: false,
              pageType: SiteDesignPageType.Chatroom
            },
            component: () => import(/* webpackChunkName: "chatroomcommentlist" */ '@/views/ChatroomCommentList.vue')
          },
          {
            path: 'chat-admin/:uid',
            props: true,
            meta: {
              dm: true,
              admin: true,
              pageType: SiteDesignPageType.Chatroom
            },
            component: () => import(/* webpackChunkName: "chatroomcommentlist" */ '@/views/ChatroomCommentList.vue')
          },
          {
            path: 'group/:uid',
            props: true,
            meta: {
              dm: false,
              admin: true,
              pageType: SiteDesignPageType.Chatroom
            },
            component: () => import(/* webpackChunkName: "chatroomcommentlist" */ '@/views/ChatroomCommentList.vue')
          }
        ]
      }, {
        path: 'group',
        meta: {
          pageType: SiteDesignPageType.UserGroup
        },
        component: () => import(/* webpackChunkName: "group" */ '@/views/Group.vue'),
        children: [
          {
            path: '',
            props: true,
            meta: {
              pageType: SiteDesignPageType.UserGroup
            },
            component: () => import(/* webpackChunkName: "groupList" */ '@/views/GroupList.vue')
          },
          {
            path: ':uid',
            props: true,
            meta: {
              pageType: SiteDesignPageType.UserGroup
            },
            component: () => import(/* webpackChunkName: "groupList" */ '@/views/GroupList.vue')
          }
        ]
      }, {
        path: 'user/:uid',
        props: true,
        component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue')
      }, {
        path: 'member',
        meta: {
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "member" */ '@/views/member/MemberMain.vue'),
        children: [
          {
            path: 'account',
            component: () => import(/* webpackChunkName: "memberAccountIndex" */ '@/views/member/MemberAccountIndex.vue')
          }, {
            path: 'account/plan',
            component: () => import(/* webpackChunkName: "memberAccountPlan" */ '@/views/member/MemberAccountPlan.vue')
          }, {
            path: 'account/user',
            component: () => import(/* webpackChunkName: "memberAccountUser" */ '@/views/member/MemberAccountUser.vue')
          }, {
            path: 'account/id',
            component: () => import(/* webpackChunkName: "memberAccountID" */ '@/views/member/MemberAccountID.vue')
          }, {
            path: 'account/password',
            component: () => import(/* webpackChunkName: "memberAccountPassword" */ '@/views/member/MemberAccountPassword.vue')
          }, {
            path: 'account/payment',
            component: () => import(/* webpackChunkName: "memberAccountPayment" */ '@/views/member/MemberAccountPayment.vue')
          }, {
            path: 'account/bill',
            component: () => import(/* webpackChunkName: "memberAccountBill" */ '@/views/member/MemberAccountBill.vue')
          }, {
            path: 'account/billDetail/:uid',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountBillDetail" */ '@/views/member/MemberAccountBillDetail.vue')
          }, {
            path: 'account/product/:uid',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountBillProduct" */ '@/views/member/MemberAccountBillProduct.vue')
          }, {
            path: 'account/product/receipt/:uid',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountBillProductReceipt" */ '@/views/member/MemberAccountBillProductReceipt.vue')
          }, {
            path: 'account/funding/:uid',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountBillFunding" */ '@/views/member/MemberAccountBillFunding.vue')
          }, {
            path: 'account/funding/receipt/:uid',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountBillFundingReceipt" */ '@/views/member/MemberAccountBillFundingReceipt.vue')
          }, {
            path: 'account/ticket/:uid',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountBillTicket" */ '@/views/member/MemberAccountBillTicket.vue')
          }, {
            path: 'account/ticket/receipt/:uid',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountBillTicketReceipt" */ '@/views/member/MemberAccountBillTicketReceipt.vue')
          }, {
            path: 'account/workflow',
            component: () => import(/* webpackChunkName: "memberAccountWorkflow" */ '@/views/member/MemberAccountWorkflow.vue')
          }, {
            path: 'account/workflow/:builderUID',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountWorkflow" */ '@/views/member/MemberAccountWorkflow.vue')
          }, {
            path: 'account/workflow/:builderUID/answer-file/:answerUID',
            props: true,
            component: () => import(/* webpackChunkName: "memberAccountWorkflow" */ '@/views/member/MemberAccountWorkflowFile.vue')
          }, {
            path: 'account/shift/report',
            component: () => import(/* webpackChunkName: "memberAccountShiftReport" */ '@/views/member/MemberAccountShiftReport.vue')
          }, {
            path: 'account/shift/report/clock',
            component: () => import(/* webpackChunkName: "memberAccountShiftClockReport" */ '@/views/member/MemberAccountShiftClockReport.vue')
          }, {
            path: 'account/shift/report/approve',
            component: () => import(/* webpackChunkName: "memberAccountShiftApproveHistoryReport" */ '@/views/member/MemberAccountShiftApproveHistoryReport.vue')
          }, {
            path: 'account/shift/report/error',
            component: () => import(/* webpackChunkName: "memberAccountShiftErrorHistoryReport" */ '@/views/member/MemberAccountShiftErrorHistoryReport.vue')
          }, {
            path: 'account/shift/report/monthly-approve',
            component: () => import(/* webpackChunkName: "memberAccountShiftApproveHistoryCountReport" */ '@/views/member/MemberAccountShiftApproveHistoryCountReport.vue')
          }, {
            path: 'account/shift/report/monthly',
            component: () => import(/* webpackChunkName: "memberAccountShiftSalaryMonthlyReport" */ '@/views/member/MemberAccountShiftSalaryMonthlyReport.vue')
          }, {
            path: 'account/shift/report/overtime',
            component: () => import(/* webpackChunkName: "memberAccountShiftSalaryMonthlyOvertimeReport" */ '@/views/member/MemberAccountShiftSalaryMonthlyOvertimeReport.vue')
          }, {
            path: 'account/shift/report/holiday',
            component: () => import(/* webpackChunkName: "memberAccountShiftSalaryMonthlyHolidayReport" */ '@/views/member/MemberAccountShiftSalaryMonthlyHolidayReport.vue')
          }, {
            path: 'archive/history',
            component: () => import(/* webpackChunkName: "memberArchiveHistory" */ '@/views/member/MemberArchiveHistory.vue')
          }, {
            path: 'archive/bookmark',
            component: () => import(/* webpackChunkName: "memberArchiveBookmark" */ '@/views/member/MemberArchiveBookmark.vue')
          }, {
            path: 'archive/live',
            component: () => import(/* webpackChunkName: "memberArchiveLive" */ '@/views/member/MemberArchiveLive.vue')
          }, {
            path: 'delivery',
            component: () => import(/* webpackChunkName: "MemberAccountDelivery" */ '@/views/member/MemberAccountDelivery.vue')
          }
        ]
      },
      {
        path: 'news/:uid',
        props: true,
        component: () => import(/* webpackChunkName: "news" */ '@/views/News.vue')
      },
      {
        path: 'stock/:uid',
        props: true,
        component: () => import(/* webpackChunkName: "stockdetail" */ '@/views/quick/StockDetail.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export function getBaseUrl(first?: boolean): string {
  let baseUrl = process.env.BASE_URL
  const hostname = window.location.hostname.toLowerCase()
  let path = location.pathname
  if (path.startsWith(baseUrl)) {
    path = path.substring(baseUrl.length)
  }
  const pathComponents = path.split('/')
    .filter(p => !!p)
  if (hostname && hostname !== process.env.VUE_APP_MAIN_DOMAIN && hostname !== 'localhost') {
  } else if (process.env.VUE_APP_DOMAIN_QUERY === '1') {
  } else if (location.pathname) {
    if (pathComponents.length > 0) {
      const domain = pathComponents[0]
      if (domain === 'lp' || domain === 'creator' || domain === 'business' || domain === '404' || pathComponents.join('/').startsWith('app/poll') || pathComponents.join('/').startsWith('player/maintenance')) {
        baseUrl = `${baseUrl}${baseUrl.lastIndexOf('/') === baseUrl.length - 1 ? '' : '/'}`
      } else {
        baseUrl = `${baseUrl}${baseUrl.lastIndexOf('/') === baseUrl.length - 1 ? '' : '/'}${pathComponents[0]}/`
      }
      pathComponents.splice(0, 1)
    }
  }
  if (first && pathComponents.length === 0 && window.location.hash) {
    let hash = window.location.hash
    if (hash.startsWith('#')) {
      hash = hash.substring(1)
    }
    if (hash.startsWith('/')) {
      const url = new URL(window.location.href)
      if (hash.indexOf('?') >= 0) {
        const hashQuery = hash.substring(hash.indexOf('?') + 1)
        const queries = hashQuery.split('&')
        for (const query of queries) {
          const values = query.split('=')
          if (values[0]) {
            url.searchParams.append(values[0], values[1])
          }
        }
        hash = hash.substring(0, hash.indexOf('?'))
      }
      url.pathname = baseUrl + (baseUrl.endsWith('/') ? hash.substring(1) : hash)
      url.hash = ''
      window.location.replace(url.toString())
    }
  }
  return baseUrl
}

const router = new VueRouter({
  mode: 'history',
  base: getBaseUrl(true),
  routes,
  scrollBehavior: (
    to: Route,
    from: Route,
    savedPosition: Position | void
  ): PositionResult | Promise<PositionResult> | undefined | null => {
    if (to.hash) {
      return { selector: to.hash }
    }
  }
})

function checkCOOP(to: Route, from: Route): Promise<RawLocation | false | void> {
  try {
    const url = new URL(window.location.href)
    if (to.path && /^\/player\/playlist\/[^/]+\/event\//g.test(to.path)) {
      // if (url.searchParams.get('coop') === '1') {
      //   return Promise.resolve()
      // }
      // url.searchParams.set('coop', '1')
      // url.hash = to.path
      // window.location.replace(url.toString())
      // return Promise.reject(MessageError.from(ErrorCode.Cancelled))
      return Promise.resolve()
    } else if (to.path && /^\/player\/zoom/g.test(to.path)) {
      return Promise.resolve()
    } else {
      if (url.searchParams.get('coop') !== '1') {
        return Promise.resolve()
      }
      url.searchParams.delete('coop')
      let baseUrl = getBaseUrl()
      if (baseUrl.endsWith('/')) {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1)
      }
      url.pathname = baseUrl + to.path
      window.location.replace(url.toString())
      return Promise.reject(MessageError.from(ErrorCode.Cancelled))
    }
  } catch (e) {

  }
  return Promise.resolve()
}

function checkDomain(to: Route, from: Route): Promise<RawLocation | false | void> {
  return new Promise((resolve, reject) => {
    if (!to.matched.some(record => record.meta.useTemplate) || (to.path || '').toLowerCase().startsWith('/business')) {
      BrowserService.favicon()
      if ((to.path || '').toLowerCase() === '/business') {
        resolve({
          path: '/',
          query: to.query,
          hash: to.hash
        })
      } else {
        resolve()
      }
      return
    }
    if (store.state.meta.logo && store.state.meta.template) {
      resolve()
      return
    }
    let domain: Promise<[string, boolean]> | null = null
    const hostname = window.location.hostname.toLowerCase()
    if (hostname && hostname !== process.env.VUE_APP_MAIN_DOMAIN && hostname !== 'localhost') {
      domain = APIService.getDomain(hostname)
        .then(d => [d, true])
    } else if (process.env.VUE_APP_DOMAIN_QUERY === '1') {
      let query = window.location.search
      if (query.startsWith('?')) {
        query = query.substring(1)
      }
      const queries = query.split('&')
      for (const q of queries) {
        const params = q.split('=')
        if (params.length === 2 && params[0] === 'd') {
          domain = Promise.resolve([params[1], false])
          break
        }
      }
    } else if (location.pathname) {
      let path = location.pathname
      const baseUrl = process.env.BASE_URL
      if (path.startsWith(baseUrl)) {
        path = path.substring(baseUrl.length)
      }
      const pathComponents = path.split('/')
        .filter(p => !!p)
      if (pathComponents.length > 0 && (pathComponents[0] || '').toLowerCase() !== 'business') {
        domain = Promise.resolve([pathComponents[0], false])
      }
    }
    if (domain) {
      return domain
        .then(d => {
          store.commit(new SubdomainActionPayload(d[0], d[1]))
          store.commit(new PlayerSubdomainActionPayload(d[0]))
          const service = new APIService(d[0])
          return service.logo()
            .then(logo => {
              store.commit(new LogoActionPayload(logo))
              store.commit(new PlayerLogoActionPayload(logo))
              return service.template(true)
            })
            .then(template => {
              store.commit(new TemplateActionPayload(template))
              store.commit(new PlayerTemplateActionPayload(template))
              let showChannelIO = 0
              let talkUID: string | undefined
              let talkPolicy = 0
              try {
                const json = JSON.parse(template.content)
                if (json.additional && json.additional.talkType === 101) {
                  showChannelIO = json.additional.talkType
                  talkUID = json.additional.talkOAuth
                  talkPolicy = json.additional.talkPolicy
                } else if (json.additional && json.additional.channelIO === 101) {
                  showChannelIO = json.additional.channelIO
                  talkUID = undefined
                  talkPolicy = 3
                }
              } catch {
                // ignore
              }
              if (showChannelIO) {
                service.channelIO(showChannelIO, talkUID)
                  .then(key => {
                    if (key.key) {
                      key.policy = talkPolicy
                      store.commit(new PlayerChannelIOActionPayload(key))
                    }
                  })
                  .catch(e => app.$log?.error(e))
              } else {
                store.commit(new PlayerChannelIOActionPayload())
              }
              return service.communityBoard()
            })
            .then(board => {
              store.commit(new BoardActionPayload(board))
              return service.btobLogo()
            })
        })
        .then(logo => {
          store.commit(new BtoBLogoActionPayload(logo))
          resolve()
        })
        .catch(e => {
          if (e.code === ErrorCode.Maintenance) {
            resolve({
              path: '/player/maintenance',
              query: {
                d: e.meta?.data ?? ''
              }
            })
          } else if (e.code === ErrorCode.NoDomain || e.code === ErrorCode.NoDomainPublic || e.code === ErrorCode.DomainBlock) {
            resolve({
              path: '/404',
              query: {
                e: e.code.toString(10),
                m: e.messageBlock ? e.messageBlock() : undefined
              }
            })
          } else {
            reject(e)
          }
        })
    } else {
      try {
        const url = new URL(window.location.href)
        for (const key of url.searchParams.keys()) {
          if (key.startsWith('ul') && key.length >= 5 && /^[a-zA-Z0-9]+$/g.test(key)) {
            window.location.href = APIService.utmUrl(window.location.origin, key)
            return
          }
        }
      } catch (e) {
        app.$log?.error(e)
      }
      resolve()
    }
    // reject(MessageError.from(ErrorCode.NoDomain))
  })
}

function checkLang(to: Route, from: Route): Promise<RawLocation | false | void> {
  return new Promise((resolve, reject) => {
    let locale = ''
    const currentLocale = i18n.locale

    // Get locale from path
    if (to.query.lang) {
      if (isString(to.query.lang)) {
        locale = to.query.lang as string
      } else {
        locale = (to.query.lang as (string | null)[])[0] ?? locale
      }
    }

    // Locale fallback
    if (!locale) locale = currentLocale

    // const lang = to.params.lang
    i18nUtil.loadLanguageAsync(locale).then(() => {
      to.params.locale = locale
      if (from.query.lang && to.query.lang && locale !== to.query.lang) {
        store.commit(new LocaleActionPayload(locale))
        resolve({
          name: to.name !== null ? to.name : undefined,
          query: { lang: locale }
        })
      } else {
        resolve()
      }
    }).catch(e => {
      reject(e)
    })
  })
}

function autoLogin(to: Route): Promise<boolean> {
  if (!to.query) {
    return Promise.resolve(false)
  }
  return new Promise((resolve) => {
    const token = to.query.sign as string
    const email = to.query.signe as string
    const loginResult = (window.location.href || '').indexOf('login-result') >= 0
    let ob: Observable<string | undefined>
    if (token && email && !loginResult) {
      const req = Proto.LoginRequest.create({
        userId: email,
        jwt: token,
        uuid: ''
      })
      ob = fromPromise(PlayerStoreService.default()
        .getItem(StoreKey.UUID + '_' + email)
        .catch(e => {
          app.$log?.error(e)
          return Promise.resolve('')
        }))
        .pipe(
          mergeMap(uuid => {
            req.uuid = uuid as string
            let isCustomLogin = to.query.customlogin === '1'
            if (isCustomLogin) {
              const hostname = window.location.hostname.toLowerCase()
              if (hostname && hostname === process.env.VUE_APP_MAIN_DOMAIN) {
                isCustomLogin = false
              }
            }
            return PlayerApiService.login(req, !isCustomLogin)
          })
        )
    } else {
      ob = of(undefined)
    }
    ob.subscribe(user => {
      if (token && email) {
        if (to.query) {
          delete to.query.sign
          delete to.query.signe
          delete to.query.customlogin
        }
      }
      resolve(!!user)
    }, error => {
      Vue.$log.error(error)
      resolve(false)
    })
  })
}

function checkAuth(to: Route, from: Route): Promise<RawLocation | false | void> {
  return new Promise((resolve) => {
    const needAuth = to.matched.some(record => record.meta.requiresAuth)
    if (store.state.playerAuth.loggedIn || store.state.playerAuth.isInitialized) {
      if (needAuth && !store.state.playerAuth.loggedIn) {
        resolve({
          path: '/'
        })
      } else {
        resolve()
      }
    } else {
      const token = to.query.sign as string
      const email = to.query.signe as string
      const loginAsUserToken = to.query.signua as string
      const loginAsUserEmail = to.query.signuae as string
      const loginAsCPToken = to.query.signcp as string
      const loginAsCPEmail = to.query.signcpe as string
      const thirdOAuthType = (app.$store?.state as State)?.playerMeta?.thirdOAuthType
      const loginResult = (window.location.href || '').indexOf('login-result') >= 0
      let ob: Observable<string | undefined>
      let loginReqOb: Observable<Proto.LoginRequest> | undefined
      if (loginAsUserToken && loginAsCPToken && !loginResult) {
        loginReqOb = new Observable<boolean>(subscriber => {
          const name = ConfirmModal.NAME + '-' + Math.random()
          app.$modal?.show(ConfirmModal, {
            name,
            title: app.$tc('player.text.modal.login_multiple_account.title'),
            message: app.$tc('player.text.modal.login_multiple_account.message'),
            okButton: app.$tc('player.text.modal.login_multiple_account.ok'),
            cancelButton: app.$tc('player.text.modal.login_multiple_account.no'),
            cancelButtonClass: 'btn-primary',
            result: (v: boolean) => {
              subscriber.next(!v)
            }
          }, {
            name,
            adaptive: true,
            scrollable: true,
            height: 'auto'
          }, {
            closed: () => {
              subscriber.complete()
            }
          })
        })
          .pipe(map(asUser => {
            if (asUser) {
              return Proto.LoginRequest.create({
                userId: loginAsUserEmail,
                jwt: loginAsUserToken,
                uuid: '',
                thirdOAuthType
              })
            } else {
              return Proto.LoginRequest.create({
                userId: loginAsCPEmail,
                jwt: loginAsCPToken,
                uuid: '',
                thirdOAuthType
              })
            }
          }))
      } else if (token && email && !loginResult) {
        loginReqOb = of(Proto.LoginRequest.create({
          userId: email,
          jwt: token,
          uuid: '',
          thirdOAuthType
        }))
      }
      if (loginReqOb) {
        ob = loginReqOb
          .pipe(
            mergeMap(req => fromPromise(PlayerStoreService.default()
              .getItem(StoreKey.UUID + '_' + req.userId)
              .catch(e => {
                app.$log?.error(e)
                return Promise.resolve('')
              }))
              .pipe(
                mergeMap(uuid => {
                  req.uuid = uuid as string
                  let isCustomLogin = true
                  if (isCustomLogin) {
                    const hostname = window.location.hostname.toLowerCase()
                    if (hostname && hostname === process.env.VUE_APP_MAIN_DOMAIN) {
                      isCustomLogin = false
                    }
                  }
                  return PlayerApiService.login(req, !isCustomLogin)
                }),
                catchError(e => PlayerApiService.authenticated(thirdOAuthType))
              )
            )
          )
      } else {
        ob = PlayerApiService.authenticated(thirdOAuthType)
      }
      ob.subscribe(user => {
        if (user) {
          if (token || loginAsUserToken || loginAsCPToken) {
            const path = Object.assign({}, to) as Location
            if (path.query) {
              delete path.query.success
              delete path.query.service
              delete path.query.sign
              delete path.query.signe
              delete path.query.signua
              delete path.query.signuae
              delete path.query.signcp
              delete path.query.signcpe
            }
            path.replace = true
            resolve(path)
          } else {
            resolve()
          }
        } else if (!needAuth) {
          resolve()
        } else {
          resolve({
            path: '/'
          })
        }
      }, error => {
        Vue.$log.error(error)
        if (!needAuth) {
          resolve()
        } else {
          resolve({
            path: '/'
          })
        }
      })
    }
  })
}

function checkLoginFirst(to: Route, from: Route): Promise<RawLocation | false | void> {
  return new Promise((resolve) => {
    const needAuth = to.matched.some(record => record.meta.requiresLoginFirst)
    if (!needAuth || store.state.playerAuth.loggedIn) {
      resolve()
      return
    }
    const template = store.state.playerMeta.template
    if (template && template.loginPage) {
      resolve({
        path: '/login'
      })
    } else {
      resolve()
    }
  })
}

let loginResultChecked = false
const loginResultCheck = () => {
  if (!loginResultChecked && window.location.hash && /[&?]login-result=\d$/g.test(window.location.hash)) {
    loginResultChecked = true
    const success = window.location.hash.endsWith('login-result=1')
    if (success) {
      setTimeout(() => {
        app.$bvToast?.toast(app.$tc('player.text.modal.join.success_login'), {
          solid: true,
          toaster: 'b-toaster-bottom-right',
          appendToast: true,
          noCloseButton: true,
          headerClass: 'd-none',
          bodyClass: 'toaster-login',
          toastClass: 'toaster-show'
        })
      }, 500)
    } else {
      setTimeout(() => {
        const name = AlertModal.NAME + '-' + Math.random()
        app.$modal?.show(AlertModal, {
          name,
          error: MessageError.from(ErrorCode.ServerLoginFail)
        }, {
          name,
          adaptive: true,
          scrollable: true,
          height: 'auto'
        })
      }, 500)
      PlayerApiService.logout()
        .subscribe()
    }

    setTimeout(() => {
      try {
        window.location.replace(window.location.href.replace(/[&?]login-result=\d/g, ''))
      } catch (e) {
        Vue.$log.error(e)
      }
    }, 1000)
  }
}

let dynamicPathChecked = false

router.beforeEach((to, from, next) => {
  Vue.$log.debug(`from: ${from.fullPath} -> to: ${to.fullPath}`)
  Vue.$log.debug(window.location.href)
  setTimeout(() => {
    try {
      if (to.meta && to.meta.pageType) {
        app?.$store?.commit(new PageUIDActionPayload(to.meta.pageType, to.meta.pageUID || ''))
      }
    } catch (e) {
      Vue.$log.error(e)
    }
  }, 0)

  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  const trident = ua.indexOf('Trident/')
  if (msie > 0 || trident > 0) {
    window.location.href = `microsoft-edge:${window.location.href}`
    setTimeout(function () {
      window.location.href = 'https://go.microsoft.com/fwlink/?linkid=2135547'
    }, 1)
    return
  }

  loginResultCheck()

  let initOb = DateService.init()
  if (to.path !== from.path && app) {
    try {
      const mainLoginToken = (app.$store?.state as State).meta.needMainLogin
      if (mainLoginToken && (app.$store?.state as State).meta.isCustom) {
        const token = mainLoginToken.sign
        const email = mainLoginToken.signe
        const req = Proto.LoginRequest.create({
          userId: email,
          jwt: token,
          uuid: ''
        })
        initOb = initOb
          .pipe(
            mergeMap(() => fromPromise(PlayerStoreService.default()
              .getItem(StoreKey.UUID + '_' + email)
              .catch(e => {
                Vue.$log.error(e)
                return Promise.resolve('')
              }))
            ),
            mergeMap(uuid => {
              req.uuid = uuid as string
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (req as any).ignoreResult = true
              return PlayerApiService.login(req)
            }),
            mergeMap((uuid): Observable<boolean> => {
              if (uuid) {
                app?.$store?.commit(new NeedMainLoginActionPayload(null))
              }
              return of(true)
            }),
            catchError(e => {
              Vue.$log.error(e)
              return of(true)
            })
          )
      }
    } catch (e) {
      Vue.$log.error(e)
    }
  }

  initOb
    .pipe(catchError(e => {
      Vue.$log.error(e)
      return of(true)
    }))
    .subscribe(d => {
      const noscriptTag = document.querySelector('noscript')
      if (noscriptTag) {
        noscriptTag.remove()
      }
      checkLang(to, from)
        // .then(_ => checkDomain(to, from))
        .then(_ => checkCOOP(to, from))
        .then(loc => {
          if (loc) {
            return loc
          }
          return checkDomain(to, from)
            .then(loc => {
              if (loc && (loc === '/404' || (loc as Location).path === '/404')) {
                if (to.path === '/404') {
                  return undefined
                }
                return autoLogin(to)
                  .catch(e => Promise.resolve(false))
                  .then(logined => {
                    if (logined) {
                      return checkDomain(to, from)
                    }
                    return Promise.resolve(loc)
                  })
              }
              return loc
            })
        })
        .then(loc => {
          app.$Progress.start()
          if (loc) {
            return loc
          }
          return checkAuth(to, from)
        })
        .catch(e => {
          if (e instanceof MessageError && e.code === ErrorCode.Cancelled) {
            return Promise.reject(e)
          }
          Vue.$log.error(e)
          if (!BrowserService.isCrawler()) {
            const name = AlertModal.NAME + '-' + Math.random()
            app.$modal.show(AlertModal, {
              name,
              error: e
            }, {
              name,
              adaptive: true,
              scrollable: true,
              height: 'auto'
            })
            store.commit(new CrawlerErrorActionPayload(false))
          } else {
            if (!BrowserService.isPrerender()) {
              BrowserService.crawlerMetaTag()
            }
            store.commit(new CrawlerErrorActionPayload(true))
          }
        })
        .then(loc => {
          if (loc) {
            if ((loc as Location).path) {
              if (store.state.playerAuth.loggedIn) {
                return loc
              }
              if (loc && (loc === '/404' || (loc as Location).path === '/404')) {
                return loc
              }
              const template = store.state.playerMeta.template
              if (template && template.loginPage) {
                return {
                  path: '/login'
                }
              }
            }
            return loc
          }
          return checkLoginFirst(to, from)
        })
        .then(loc => {
          if (loc && from.fullPath === (loc as Location).path) {
            app.$Progress.finish()
          }
          try {
            const subdomain = store.state.playerMeta.subdomain
            if ((subdomain === 'phoenixdarts' || subdomain === 'affiliatepd' || subdomain === 'prototypesb') && !store.getters.isCompanyOwner) {
              window.location.href = 'https://liteview.jp/404'
              return
            }
          } catch (e) {
          }
          if (to.path && (to.path.startsWith('/forum/') || to.path === '/forum')) {
            const board = (app.$store?.state as State).meta.board
            if (!board || !board.uid || board.topicPolicy === 0) {
              next({
                path: '/'
              })
              return
            }
          }
          try {
            const needHideChannelIO = to.matched.some(record => record.meta && record.meta.hideChannelIO)
            if (needHideChannelIO) {
              ChannelIOService.hideChannelButton()
            } else {
              ChannelIOService.showChannelButton()
            }
          } catch (e) {
            app.$log?.error(e)
          }
          if (!dynamicPathChecked && to.path === '/404' && (to.redirectedFrom && to.redirectedFrom !== to.path)) {
            dynamicPathChecked = true
            next({
              path: to.redirectedFrom
            })
            return
          }
          next(loc)
        })
    })
})
//  hook the progress bar to finish after we've finished moving router-view
router.afterEach((e) => {
  //  finish the progress bar
  app.$Progress.finish()
  if (e.fullPath && e.fullPath !== '/') {
    BrowserService.dispatchEvent('liteview.route.load', {
      bubbles: true,
      cancelable: false,
      detail: {
        route: e.fullPath
      }
    })
  }
})
router.onError(err => {
  console.error(err)
  try {
    if (err.name === 'ChunkLoadError') {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          if (!registrations.length) {
            return
          }
          Promise.all(registrations.map((registration) => registration.unregister()
            .catch(_ => {
            })))
            .then(() => {
              console.error('remove sw')
              window.location.reload()
            })
        })
      }
    }
  } catch (e) {
    console.error(e)
  }
})

export default router
